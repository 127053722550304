import axios from 'axios'
import success from '@/interceptors/response/success'
import failure from '@/interceptors/response/failure'

const api = axios.create({
    baseURL: process.env.VUE_APP_URL_API
})

api.interceptors.request.use((config) => {
    const token = localStorage.getItem('jwt.token')

    config.headers.Authorization = `Bearer ${token}`

    return config
})

api.interceptors.response.use(success, failure)

export default api
