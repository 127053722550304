<style scoped>
.fa-tag {
    transform: rotateY(180deg);
}
</style>

<template>
    <div class="font-body">
        <div class="h-screen flex">
            <!--MOBILE SIDEBAR-->
            <div v-if="sidebarIsOpen" class="lg:hidden">
                <div class="fixed inset-0 flex flex-shrink-0 z-40">
                    <div class="fixed inset-0" aria-hidden="true">
                        <div class="absolute inset-0 bg-gray-600 opacity-75" @click.self="sidebarIsOpen = false"></div>
                    </div>
                    <div class="relative flex flex-col bg-grey-dark">
                        <div class="absolute top-2 right-2">
                            <button
                                class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                @click="sidebarIsOpen = false"
                            >
                                <span class="sr-only">Close sidebar</span>
                                <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>
                        <div class="flex flex-1">
                            <Sidebar @openModal="openModal" />
                        </div>
                    </div>
                </div>
            </div>

            <!-- DESKTOP SIDEBAR -->
            <div class="hidden lg:flex lg:flex-shrink-0">
                <Sidebar @openModal="openModal" />
            </div>

            <div class="flex flex-col w-0 flex-1 ">
                <div class="relative z-10 flex-shrink-0 flex sm:h-24 bg-grey-light shadow px-4">
                    <div class="w-full px-4 flex sm:flex-wrap justify-between items-center my-3">
                        <div class="flex-1 flex lg:ml-1">
                            <h2 class="text-2xl font-bold">Keyros.io</h2>
                        </div>
                        <div class="ml-4 flex items-center lg:ml-6 relative">
                            <button @click="dropdownOpen = !dropdownOpen" class=" p-1 rounded-full text-deeppurple-dark hover:text-error-pink focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                <span class="sr-only">View notifications</span>
                                <!-- Heroicon name: bell -->
                                <svg class="h-8 w-8" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                                </svg>
                            </button>
                            <div v-if="notifications_length > 0" class="badge absolute top-0 right-1 w-15px h-15px bg-error-dark text-white flex justify-center items-center rounded-full">
                                <span v-if="loaded = true" class="text-xs"> {{notifications_length}} </span>
                            </div>
                            <DropDownNotifications v-if="dropdownOpen" :notifications="notifications" @closeDropDownNotifications="closeDropDown"
                                                    @changeNotificationNumber="changeNotificationNumber"></DropDownNotifications>
                        </div>
                        <div class="ml-4 hidden lg:flex">
                            <i class="fas fa-sign-out-alt"></i>
                            <button @click.prevent="logout">
                                <font-awesome-icon :icon="['fas', 'sign-out-alt']" :class="'text-2xl text-deeppurple-dark hover:text-error-pink'" />
                            </button>
                        </div>
                    </div>
                    <burger-menu @openSideBar="sidebarIsOpen = true"></burger-menu>
                </div>

                <main class="overflow-y-auto flex flex-col flex-1">
                    <router-view name="page"/>
                </main>
                <Modal v-if="modalShowing" ref="modal"/>
            </div>
        </div>
    </div>
</template>

<script>
import BurgerMenu from '../Shared/BurgerMenu'
import Sidebar from '../Shared/Sidebar'
import api from '@/clients/api'
import DropDownNotifications from '@/layouts/DropDownNotifications'
import Modal from '@/components/Modal/MonCompteModal'
import { tokenRepository } from '@/container'
export default {
    components: { DropDownNotifications, Sidebar, BurgerMenu, Modal },
    props: {
        title: String
    },
    data () {
        return {
            sidebarIsOpen: false,
            notifications: [],
            dropdownOpen: false,
            notification_read_at: [],
            notifications_length: null,
            loaded: false,
            modalShowing: this.$route.meta.modalShowing
        }
    },
    created () {
        this.getNotifications()
    },
    watch: {
        title: {
            immediate: true,
            handler (title) {
                document.title = title
            }
        },
        '$route' () {
            this.sidebarIsOpen = false
        },
        '$route.meta' ({ modalShowing }) {
            this.modalShowing = modalShowing
        }
    },
    methods: {
        openModal () {
            this.$router.push({ name: 'Profile' })
        },
        closeDropDown () {
            this.dropdownOpen = false
            this.getNotifications()
        },
        getNotifications () {
            const formData = new FormData()
            formData.append('filterRequest', 'dropdown')
            api.post('/notifications/all', formData)
                .then(response => {
                    this.notifications = response.data
                    this.notifications_length = this.notifications.data.length
                    this.loaded = true
                })
                .catch(function (error) {
                    console.error(error.response)
                })
        },
        changeNotificationNumber () {
            this.getNotifications()
        },
        logout () {
            tokenRepository().destroy()
            this.$router.push({ name: 'Login' })
        }
    }
}
</script>
