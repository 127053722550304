export default {
    namespaced: true,
    state: {
        showModal: false,
        showValidationModal: false
    },
    getters: {
        showModal: state => {
            return state.showModal
        },
        showValidationModal: state => {
            return state.showValidationModal
        }
    },
    mutations: {
        toggleModal(state) {
            state.showModal = !state.showModal
        },
        toggleValidationModal(state) {
            state.showValidationModal = !state.showValidationModal
        }
    }
}
