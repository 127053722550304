import { taxRepository } from '@/container'

function initialState() {
    return {
        tax_id: null,
        taxe_name: null,
        taxe_taux: null,
        tax_country_id: null
    }
}

export default {
    namespaced: true,
    state: {
        taxes: {},
        tax: {},
        ...initialState()
    },
    getters: {},
    actions: {
        findOneBy ({ commit }, id) {
            taxRepository().getById(id).then(response => {
                commit('SET_TAX', response)
            })
        },
        fetchAll ({ commit }) {
            taxRepository().get().then(response => {
                commit('SET_TAXES', response)
            })
        },
        create ({ commit }, {vm, formData}) {
            taxRepository().store(formData).then(response => {
                commit('ADD_TAX', response.tax)
                vm.$toasted.success(response.message, {
                    position: 'top-center',
                    duration: 2000
                })
            }).catch(error => {
                throw new Error(`API ${error}`)
            })
        },
        update ({ commit }, {vm, data}) {
            taxRepository().update(data).then(response => {
                commit('UPDATE_TAX', response.tax, data.id)
                vm.$toasted.success(response.message, {
                    position: 'top-center',
                    duration: 2000
                })
            }).catch(error => {
                throw new Error(`API ${error}`)
            })
        },
        delete ({ commit }, {vm, data}) {
            taxRepository().delete(data.id).then(response => {
                commit('DELETE_TAX', data.id)
                vm.$toasted.success(response, {
                    position: 'top-center',
                    duration: 2000
                })
            }).catch(error => {
                throw new Error(`API ${error}`)
            })
        },
        reset ({ commit }) {
            commit('RESET_STATE')
        }
    },
    mutations: {
        SET_TAXES (state, taxes) {
            state.taxes = taxes
        },
        SET_TAX (state, tax) {
            state.tax = tax
        },
        ADD_TAX (state, tax) {
            state.taxes.push(tax)
        },
        UPDATE_TAX (state, tax, id) {
            const index = state.taxes.findIndex(tax => tax.id === id)
            state.taxes[index] = tax
        },
        DELETE_TAX (state, id) {
            const index = state.taxes.findIndex(tax => tax.id === id)
            state.taxes.splice(index, 1)
        },
        RESET_STATE: (state) => {
            Object.assign(state, initialState())
        },
    }
}
