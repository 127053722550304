<template>
    <div class="mt-2">
        <nav class="text-grey-dark font-bold text-xs md:text-sm" aria-label="Breadcrumb">
            <ul class="list-none py-3 mb-3 inline-flex tracking-wide items-center">
                <li class="flex items-center">
                    <router-link :to="{ name: 'Customers' }">Clients</router-link>
                </li>
                <li class="flex items-center">
                    <svg class="fill-current w-3 h-3 mx-2 xl:mx-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                        <path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"/>
                    </svg>
                    <span class="text-grey-regular">{{ customer.name }} </span>
                </li>
                <li v-if="isEstimate" class="flex items-center">
                    <svg class="fill-current w-3 h-3 mx-2 xl:mx-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                        <path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"/>
                    </svg>
                    <span class="text-grey-regular">Liste de mes devis</span>
                </li>
                <li v-else-if="isInvoice" class="flex items-center">
                    <svg class="fill-current w-3 h-3 mx-2 xl:mx-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                        <path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"/>
                    </svg>
                    <span class="text-grey-regular">Liste de mes factures</span>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script>
export default {
    props: ['customer', 'isEstimate', 'isInvoice'],
    data () {
        return {
            redirectTo: '/customers/' + this.customer.id
        }
    }
}
</script>
