<template>
    <div v-if="loaded">
    <section class="px-10 mb-8 relative flex flex-col flex-1">
        <Breadcrumb :customer="customer" :isEstimate="false" :isInvoice="false"/>
        <section class="content relative">
            <div class="h-screen flex overflow-auto">
                <div class="flex overflow-auto">
                    <!-- Start left area-->
                        <div class="absolute left-0 float-left w-2/3 overflow-x-auto">
                            <!-- Prestation -->
                            <div class="h-full rounded-lg">
                                <working-hours
                                    :fileType="benefitTitle"
                                    @addFile="addBenefit"
                                    @redirectTo="redirectToWorkingHours">
                                </working-hours>
                            </div>
                            <!-- Estimates -->
                            <div class="h-full rounded-lg">
                                <cards-section
                                    :fileType="estimateTitle"
                                    :files="estimates"
                                    @addFile="addEstimate"
                                    @redirectTo="redirectToEstimates"
                                    @edit="editEstimate"
                                    @askDelete="askEstimateConfirm" />
                            </div>
                            <!-- Invoices -->
                            <div class="h-full rounded-lg">
                                <cards-section
                                    :fileType="invoiceTitle"
                                    :files="invoices"
                                    @addFile="addInvoice"
                                    @redirectTo="redirectToInvoices"
                                    @edit="editInvoice"
                                    @askDelete="askInvoiceConfirm" />
                            </div>
                        </div>
                        <!-- End left area -->
                        <!-- Start secondary column -->
                        <div class="absolute right-0 float-right w-1/3 pl-4">
                            <div class="h-full rounded-lg">
                                <customer-informations
                                    :customer="customer"
                                    :isDeleted="isDeleted"
                                    @edit="editCustomer"
                                    @confirm="askConfirm" />
                            </div>
                        </div>
                        <!-- End secondary column -->
                </div>
            </div>
        </section>
        <!-- MODAL EDIT CUSTOMER -->
        <router-view name="modalCustomer"/>
        <add-customer-modal v-if="show" :isEdition="isEdition" @reloadDetail="loadCustomer" :modalTitle="modalTitle" :id="id" :routeName="'CustomersDetails'" ref="modalCustomer"/>
        <!-- CONFIRMATION MODAL -->
        <ConfirmModal
            v-if="modalIsOpen"
            :customer="customer"
            :estimateId="estimateId"
            :invoiceId="invoiceId"
            @closeModal="closeModal"
            @deleteCustomer="deleteCustomer"
            @deleteEstimate="deleteEstimate"
            @deleteInvoice="deleteInvoice"
            @restore="restoreCustomer"
            ref="confirmModal" />
    </section>
    </div>
</template>

<script>
import api from '@/clients/api'
import AddCustomerModal from '../../components/Customers/Modal/AddCustomerModal'
import Breadcrumb from '@/components/Customers/Breadcrumb'
import CustomerInformations from '../../components/Customers/Informations/CustomerInformations.vue'
import ConfirmModal from '../../components/Customers/Modal/ConfirmModal'
import CardsSection from '../../components/Customers/Cards/CardsSection.vue'
import WorkingHours from '../../components/Customers/WorkingHours/CustomerWorkingHours.vue'

export default {
    metaInfo: { title: 'Mes clients | Projeo.io' },
    components: {
        AddCustomerModal,
        Breadcrumb,
        CustomerInformations,
        ConfirmModal,
        CardsSection,
        WorkingHours
    },
    props: {
        errors: Object
    },
    data () {
        return {
            id: this.$route.params.id,
            estimateId: '',
            invoiceId: '',
            modalTitle: 'Modifier un client',
            benefitTitle: {
                title: 'Saisies de temps'
            },
            estimateTitle: {
                title: 'Devis',
                name: 'Devis',
                add: 'un devis'
            },
            invoiceTitle: {
                title: 'Factures',
                name: 'Facture',
                add: 'une facture'
            },
            isEdition: true,
            modalIsOpen: false,
            isDeleted: false,
            loaded: false,
            show: this.$route.meta.show,
            customer: {
                id: '',
                name: '',
                company_name: '',
                company_number: '',
                street_number: '',
                street_address: '',
                zip: '',
                city: '',
                email: '',
                phone_number: '',
                mobile_number: '',
                currency: '',
                language: '',
                country: '',
                deleted_at: ''
            },
            estimates: [
                {
                    id: null,
                    number: ''
                },
                {
                    id: null,
                    number: ''
                },
                {
                    id: null,
                    number: ''
                }
            ],
            invoices: [
                {
                    id: null,
                    number: ''
                },
                {
                    id: null,
                    number: ''
                },
                {
                    id: null,
                    number: ''
                }
            ]
        }
    },
    created () {
        this.loadCustomer(this.id)
        this.loadEstimates(this.id)
        this.loadInvoices(this.id)
    },
    methods: {
        loadCustomer (id) {
            if (id) {
                api.get('/customers/' + id)
                    .then(result => {
                        this.customer.id = result.data.data.id
                        this.customer.name = result.data.data.name
                        this.customer.company_name = result.data.data.company_name
                        this.customer.company_number = result.data.data.company_number
                        this.customer.email = result.data.data.email
                        this.customer.street_number = result.data.data.street_number
                        this.customer.street_address = result.data.data.address
                        this.customer.zip = result.data.data.zip_code
                        this.customer.city = result.data.data.city
                        this.customer.phone_number = result.data.data.phone_number
                        this.customer.mobile_number = result.data.data.mobile_number
                        this.customer.country = result.data.data.country
                        this.customer.language = result.data.data.language
                        this.customer.currency = result.data.data.currency
                        this.customer.deleted_at = result.data.data.deleted_at
                        this.customer.deleted_at ? this.isDeleted = true : this.isDeleted = false
                        this.loaded = true
                    })
                    .catch(error => {
                        this.$router.push({ name: 'Customers' }, () => {
                            this.$toasted.error(error.response.data.message, {
                                position: 'top-center',
                                duration: 2000
                            })
                        })
                        throw new Error(`API ${error}`)
                    })
            }
        },
        loadEstimates (id) {
            api.get('/customers/' + id + '/estimates')
                .then(result => {
                    if (result.data.data[0]) {
                        this.estimates[0].id = result.data.data[0].id
                        this.estimates[0].number = result.data.data[0].estimate_number
                    }
                    if (result.data.data[1]) {
                        this.estimates[1].id = result.data.data[1].id
                        this.estimates[1].number = result.data.data[1].estimate_number
                    }
                    if (result.data.data[2]) {
                        this.estimates[2].id = result.data.data[2].id
                        this.estimates[2].number = result.data.data[2].estimate_number
                    }
                })
                .catch(error => {
                    this.$toasted.error(error.response.data.message, {
                        position: 'top-center',
                        duration: 2000
                    })
                    throw new Error(`API ${error}`)
                })
        },
        loadInvoices (id) {
            api.get('/customers/' + id + '/invoices')
                .then(result => {
                    if (result.data.data[0]) {
                        this.invoices[0].id = result.data.data[0].id
                        this.invoices[0].number = result.data.data[0].invoice_number
                    }
                    if (result.data.data[1]) {
                        this.invoices[1].id = result.data.data[1].id
                        this.invoices[1].number = result.data.data[1].invoice_number
                    }
                    if (result.data.data[2]) {
                        this.invoices[2].id = result.data.data[2].id
                        this.invoices[2].number = result.data.data[2].invoice_number
                    }
                })
                .catch(error => {
                    this.$toasted.error(error.response.data.message, {
                        position: 'top-center',
                        duration: 2000
                    })
                    throw new Error(`API ${error}`)
                })
        },
        editCustomer (customer) {
            this.$router.push({ name: 'EditCustomers', params: { id: customer.id } })
        },
        askConfirm () {
            this.modalIsOpen = true
        },
        closeModal () {
            this.estimateId = ''
            this.invoiceId = ''
            this.modalIsOpen = false
        },
        deleteCustomer (customerId) {
            api.delete('/customers/' + customerId + '/delete')
                .then(result => {
                    this.$router.push({ name: 'Customers' }, () => {
                        this.$toasted.success(result.data.message, {
                            position: 'top-center',
                            duration: 2000
                        })
                    })
                })
                .catch(error => {
                    this.$toasted.error('Erreur: le client n\'a pas pu être supprimé.', {
                        position: 'top-center',
                        duration: 2000
                    })
                    throw new Error(`API ${error}`)
                })
        },
        restoreCustomer (customerId) {
            api.put('/customers/' + customerId + '/restore')
                .then(result => {
                    this.$router.push({ name: 'Customers' }, () => {
                        this.$toasted.success(result.data.message, {
                            position: 'top-center',
                            duration: 2000
                        })
                    })
                })
                .catch(error => {
                    this.$toasted.error(error.response.data.message, {
                        position: 'top-center',
                        duration: 2000
                    })
                    throw new Error(`API ${error}`)
                })
        },
        redirectToWorkingHours () {
            this.$router.push({ name: 'WorkingHours' })
        },
        redirectToEstimates () {
            this.$router.push({ name: 'CustomersEstimates', params: { customerId: this.customer.id } })
        },
        redirectToInvoices () {
            this.$router.push({ name: 'CustomersInvoices', params: { customerId: this.customer.id } })
        },
        addBenefit () {
            console.log('Go to ajouter une prestation')
        },
        addEstimate () {
            this.$router.push({ name: 'AddEstimate' })
        },
        addInvoice () {
            this.$router.push({ name: 'AddInvoice' })
        },
        editEstimate (id) {
            console.log('redirect to edit estimate : ' + id)
        },
        editInvoice (id) {
            console.log('redirect to edit invoice : ' + id)
        },
        askEstimateConfirm (id) {
            this.estimateId = id
            this.modalIsOpen = true
        },
        askInvoiceConfirm (id) {
            this.invoiceId = id
            this.modalIsOpen = true
        },
        deleteEstimate (estimateId) {
            api.delete('/estimates/' + estimateId + '/delete')
                .then(result => {
                    this.loadEstimates(this.id)
                    console.log(result)
                    this.$toasted.success(result.data.message, {
                        position: 'top-center',
                        duration: 2000
                    })
                })
                .catch(error => {
                    this.$toasted.error('Erreur: le devis n\'a pas pu être supprimé.', {
                        position: 'top-center',
                        duration: 2000
                    })
                    throw new Error(`API ${error}`)
                })
        },
        deleteInvoice (invoiceId) {
            api.delete('/invoices/' + invoiceId + '/delete')
                .then(result => {
                    this.loadInvoices(this.id)
                    this.$toasted.success(result.data.message, {
                        position: 'top-center',
                        duration: 2000
                    })
                })
                .catch(error => {
                    this.$toasted.error('Erreur: la facture n\'a pas pu être supprimé.', {
                        position: 'top-center',
                        duration: 2000
                    })
                    throw new Error(`API ${error}`)
                })
        }
    },
    watch: {
        '$route.meta' ({ show }) {
            this.show = show
        }
    }
}
</script>
