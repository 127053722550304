export default function (client, tokenRepository) {
    /** @type {boolean} */
    const authenticated = !!tokenRepository().read()

    /**
     * Login.
     * @param {string} email - The email of the user.
     * @param {string} password - The password of the user.
     * @throws {Error} Wrong credentials.
     * @returns {Promise} Promise object represents the HTTP request.
     */
    function login (email, password) {
        return client
            .post('/oauth/token', {
                grant_type: 'password',
                client_id: process.env.VUE_APP_CLIENT_ID,
                client_secret: process.env.VUE_APP_CLIENT_SECRET,
                username: email,
                password: password
            })
    }

    /**
     * resetPassword.
     * @param {string} email - The email of the user.
     * @throws {Error} Wrong credentials.
     * @returns {Promise} Promise object represents the HTTP request.
     */
    function resetPassword (email) {
        return client.post('/password/reset', {
            grant_type: 'password',
            client_id: process.env.VUE_APP_CLIENT_ID,
            client_secret: process.env.VUE_APP_CLIENT_SECRET,
            username: email,
        });
    }

    /**
     * Logout.
     * @returns {void}
     */
    function logout () {
        tokenRepository().destroy()
    }

    return {
        login,
        resetPassword,
        logout,
        authenticated
    }
}
