import { customerRepository } from '@/container'

export default {
    namespaced: true,
    state: {
        customers: {},
        customersPendingInvitation: {},
        customerCount: 0
    },
    getters: {},
    actions: {
        create ({ commit }, {vm, formData}) {
            customerRepository().store(formData).then(response => {
                commit('ADD_CUSTOMER', response.customer)
                vm.$toasted.success(response.message, {
                    position: 'top-center',
                    duration: 2000
                })
            }).catch(error => {
                vm.$toasted.error("Le formulaire n'est pas valide", {
                    position: 'top-center',
                    duration: 2000
                })
                throw new Error(`API ${error}`)
            })
        },
        getCustomer ({ commit }, id) {
            customerRepository().getCustomer(id).then(response => {
                commit('SET_CUSTOMER', response)
            })
        },
        getCustomers ({ commit }) {
            customerRepository().getCustomers().then(response => {
                commit('SET_CUSTOMERS', response.group_users)
            })
        },
        getCustomersPending ({ commit }) {
            customerRepository().getCustomers().then(response => {
                commit('SET_CUSTOMERS_PENDING_INVITATION', response.group_users_pending_invitations)
            })
        },
        deleteCustomer ({ commit }, id) {
            customerRepository().delete(id).then(response => {
                commit('DELETE_CUSTOMER', id)
                this.$toasted.success(response.data.message, {
                    position: 'top-center',
                    duration: 2000
                })
            })
            .catch(error => {
                this.$toasted.error('Erreur: le client n\'a pas pu être supprimé.', {
                    position: 'top-center',
                    duration: 2000
                })
                throw new Error(`API ${error}`)
            })
        }
    },
    mutations: {
        ADD_CUSTOMER (state, customer) {
            state.customers.push(customer)
        },
        SET_CUSTOMERS (state, customers) {
            state.customers = customers
        },
        SET_CUSTOMERS_PENDING_INVITATION (state, customers) {
            state.customersPendingInvitation = customers
        },
        SET_CUSTOMER_COUNT (state, customers) {
            state.customerCount = customers
        },
        SET_CUSTOMER (state, customer) {
            state.customer = customer
        },
        DELETE_CUSTOMER (state, id) {
            let index = state.customers.findIndex(customer => customer.id == id)
            state.customers.splice(index, 1)
        }
    }
}
