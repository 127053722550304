import { userRepository } from '@/container'

export default {
    namespaced: true,
    state: {
        user: {},
    },
    getters: {},
    actions: {
        getUserConnectedProfile ({ commit }) {
            userRepository().getUserProfile().then(response => {
                commit('SET_USER_PROFILE', response.data)
            })
        },
    },
    mutations: {
        SET_USER_PROFILE (state, user) {
            state.user = user
        }
    }
}
