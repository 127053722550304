<template>
    <transition name="fade">
        <div class="fixed z-10 inset-0 overflow-y-auto">
            <div class="flex items-center justify-center h-screen text-center sm:block sm:p-0">
                <div class="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div @click.self="closeModal" class="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>
                <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                <div
                    class="xl:max-w-3xl md:max-w-xl max-w-full mx-0 sm:mx-4 inline-block align-middle bg-white
                    rounded-lg px-4 pb-4 text-left shadow-xl transform transition-all"
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline"
                >
                    <div class="sm:flex sm:items-start">
                        <div class="my-3 mx-5 justify-center sm:ml-4">
                            <h3 class="text-xl text-center leading-6 font-bold text-deeppurple-dark"
                                id="modal-headline">
                                {{ modalTitle }}
                            </h3>
                            <p class="pb-2 text-xs text-left text-blacktext-dark" v-if="description">
                                {{ description }}
                            </p>
                            <div class="mt-2">
                                <slot/>
                                <div class="space-x-3 py-5 flex justify-center">
                                    <button
                                        @click="$emit('close-modal')"
                                        type="button"
                                        class="px-8 py-2 bg-gray-700 text-white hover:bg-gray-500 rounded-2xl shadow-md"
                                    >
                                        Annuler
                                    </button>
                                    <button
                                        v-if="isEdition === false"
                                        @click.prevent="save"
                                        class="px-8 py-2 projeo-btn-deeppurple rounded-2xl shadow-md"
                                    >
                                        Ajouter
                                    </button>
                                    <button
                                        v-else
                                        @click.prevent="update"
                                        class="px-8 py-2 projeo-btn-deeppurple rounded-2xl shadow-md"
                                    >
                                        Modifier
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    props: ['modalTitle', 'description', 'isEdition'],
    methods: {
        closeModal () {
            this.$emit('close-modal')
        },
        save () {
            this.$emit('save')
        },
        update () {
            this.$emit('update')
        }
    }
}
</script>
