import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/Login.vue'
import Profile from '@/views/Auth/Account/Profile'
import Subscription from '@/views/Auth/Account/Subscription'
import Dashboard from '@/views/Dashboard'
import Customers from '@/views/Customers/List'
import CustomersDetails from '@/views/Customers/Details'
import StepOne from '@/views/Auth/Registration/stepOne'
import Benefits from '@/views/Benefits'
import NotificationsList from '@/views/Notifications/NotificationsList'
import DashboardCustomer from '@/views/Customers/DashboardCustomer'
import Calendar from '@/views/Calendar/Calendar'
import AppointmentTypes from "@/views/AppointmentTypes"
import VerifEmail from '@/views/VerifEmail'
import PasswordReset from '@/views/Auth/PasswordReset'
import Availabilities from '@/views/Availabilities'
import PaymentExplanation from '@/views/PaymentExplanation'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Login',
        component: Login,
        meta: {
            layout: 'auth',
            guest: true
        }
    },
    {
        path: '/password/reset',
        name: 'PasswordReset',
        component: PasswordReset,
        meta: {
            layout: 'auth',
            guest: true
        }
    },
    {
        path: '/register',
        name: 'StepOne',
        component: StepOne,
        meta: {
            layout: 'auth',
            guest: true
        }
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        components: {
            page: Dashboard
        },
        meta: {
            layout: 'dashboard',
            requiresAuth: true,
            show: false
        }
    },
    {
        path: '/dashboard/customers',
        name: 'DashboardCustomer',
        components: {
            page: DashboardCustomer
        },
        meta: {
            layout: 'dashboard',
            requiresAuth: true,
            show: false
        }
    },
    {
        path: '/dashboard/customers',
        name: 'DashboardCustomer',
        components: {
            page: DashboardCustomer
        },
        meta: {
            layout: 'dashboard',
            requiresAuth: true,
            show: false
        }
    },
    {
        path: '/notifications',
        name: 'Notifications',
        components: {
            page: NotificationsList
        },
        meta: {
            layout: 'dashboard',
            requiresAuth: true,
            modalShowing: false
        }
    },
    {
        path: '/account/profile',
        name: 'Profile',
        components: {
            page: Profile
        },
        props: true,
        meta: {
            layout: 'dashboard',
            requiresAuth: true
        }
    },
    {
        path: '/account/subscription',
        name: 'Subscription',
        components: {
            page: Subscription
        },
        props: true,
        meta: {
            layout: 'dashboard',
            requiresAuth: true
        }
    },
    {
        path: '/benefits',
        name: 'Benefits',
        components: {
            page: Benefits
        },
        meta: {
            layout: 'dashboard',
            requiresAuth: false,
            modalShowing: false,
            categorie: 'entreprise'
        }
    },
    {
        path: '/availabilities',
        name: 'Availabilities',
        components: {
            page: Availabilities
        },
        meta: {
            layout: 'dashboard',
            requiresAuth: false,
            modalShowing: false,
            categorie: 'entreprise'
        }
    },
    {
        path: '/appointment-types',
        name: 'AppointmentTypes',
        components: {
            page: AppointmentTypes
        },
        meta: {
            layout: 'dashboard',
            requiresAuth: false,
            modalShowing: false,
            categorie: 'entreprise'
        }
    },
    {
        path: '/calendar',
        name: 'Calendar',
        components: {
            page: Calendar
        },
        meta: {
            layout: 'dashboard',
            requiresAuth: true
        }
    },
    {
        path: '/customers',
        name: 'Customers',
        components: {
            page: Customers
        },
        meta: {
            layout: 'dashboard',
            requiresAuth: true
        }
    },
    {
        path: '/customers/:id',
        name: 'CustomersDetails',
        components: {
            page: CustomersDetails
        },
        props: true,
        meta: {
            layout: 'dashboard',
            requiresAuth: true,
            breadcrumb: [
                {
                    name: 'Clients',
                    link: 'Customers'
                },
                {
                    name: 'Fiche',
                    link: 'Customers'
                }
            ]
        }
    },
    {
        path: '/customers/search',
        name: 'SearchCustomers',
        components: {
            page: Customers
        },
        meta: {
            layout: 'dashboard',
            requiresAuth: true
        }
    },
    {
        path: '/email/verification',
        name: 'VerifEmail',
        components: {
            page: VerifEmail
        },
        meta: {
            layout: 'dashboard',
            requiresAuth: true
        }
    },
    {
        path: '/account/put-online',
        name: 'PutAccountOnline',
        components: {
            page: PaymentExplanation
        },
        meta: {
            layout: 'dashboard',
            requiresAuth: true
        }
    }
]

const router = new VueRouter({
    base: '/',
    mode: 'history',
    routes,
    linkExactActiveClass: 'bg-grey-hover border-deeppurple-regular'
})

export default router
