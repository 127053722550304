<template>
    <date-picker
        class="inline-block h-full"
        v-model="date"
        mode="datetime"
        :masks="masks"
        is24hr
    >
        <template v-slot="{ inputValue, togglePopover }">
            <div class="flex items-center">
                <button
                    class="p-2 text-deeppurple-dark bg-purple-200 border border-purple-200 hover:bg-purple-400 rounded-l focus:bg-purple-500 focus:text-white focus:border-blue-500 focus:outline-none"
                    @click.prevent.stop="togglePopover({ placement: 'auto-start' })"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        class="w-4 h-4 fill-current"
                    >
                        <path
                            d="M1 4c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z"
                        ></path>
                    </svg>
                </button>
                <label>
                    <input
                        :value="emitDate(inputValue)"
                        class="bg-white text-gray-700 w-full py-1 px-2 appearance-none border rounded-r focus:outline-none focus:border-deeppurple-dark"
                        readonly
                    />
                </label>
            </div>
        </template>
    </date-picker>
</template>

<script>
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
export default {
    components: {
        DatePicker
    },
    props: ['dateProp'],
    data () {
        return {
            date: new Date(),
            masks: {
                input: 'YYYY-MM-DD H:I',
            },
        }
    },
    methods: {
        emitDate (inputValue) {
            this.$emit('setDate', inputValue)
            return inputValue
        }
    },
    mounted () {
        if (this.dateProp === null || this.dateProp === undefined) {
            this.date = new Date()
        } else {
            this.date = this.dateProp
        }
    }
}
</script>
