import router from '../../router/index.js'

export default function (error) {
    switch (error.response.status) {
        case 401:
            localStorage.removeItem('jwt.token')
            router.push({
                name: 'Login'
            })
            break
        case 403:
            if(error.response.data.message === 'Your email address is not verified.') {
                router.push({name: 'VerifEmail'})
            }
            break;
    }

    return Promise.reject(error)
}
