/**
 * The dependency container.
 */
import api from '@/clients/api'
import TokenRepository from '@/repositories/TokenRepository'
import AuthenticationService from '@/services/AuthenticationService'
import CustomerRepository from '@/repositories/CustomerRepository'
import WorkingHourRepository from '@/repositories/WorkingHourRepository'
import UserRepository from '@/repositories/UserRepository'
import BenefitRepository from '@/repositories/BenefitRepository'
import StatisticRepository from '@/repositories/StatisticRepository'
import AppointmentTypeRepository from '@/repositories/AppointmentTypeRepository'
import AvailabilityRepository from '@/repositories/AvailabilityRepository'

/**
 * Bind the authentication service.
 * @returns {Object} The authentication service.
 */
export function authenticationService () {
    return new AuthenticationService(
        api,
        tokenRepository
    )
}

/**
 * Bind the token repository.
 * @returns {Object} The token repository.
 */
export function tokenRepository () {
    return new TokenRepository()
}

/**
 * Bind the working hour repository.
 * @returns {Object} The working hour repository.
 */
export function workingHourRepository () {
    return new WorkingHourRepository(api)
}

/**
 * Bind the customer repository.
 * @returns {Object} The token repository.
 */
export function customerRepository () {
    return new CustomerRepository(api)
}

/**
 * Bind the user repository.
 * @returns {Object} The token repository.
 */
export function userRepository () {
    return new UserRepository(api)
}

/**
 * Bind the currency repository.
 * @returns {Object} The token repository.
 */
export function benefitRepository () {
    return new BenefitRepository(api)
}

/**
 * Bind the currency repository.
 * @returns {Object} The token repository.
 */
export function appointmentTypeRepository () {
    return new AppointmentTypeRepository(api)
}

/**
 * Bind the currency repository.
 * @returns {Object} The token repository.
 */
export function statisticRepository () {
    return new StatisticRepository(api)
}

/**
 * Bind the currency repository.
 * @returns {Object} The token repository.
 */
export function availabilityRepository () {
    return new AvailabilityRepository(api)
}
