/**
 * The benefits repository.
 * @author Christophe Lablancherie <clablancherie@projeo.io>
 * @param {Object} client - The injected client.
 * @returns {Object} The public object.
 */
export default function (client) {
    return {
        /**
         * Get all tenant benefits.
         * @returns {Promise} Promise object represents the HTTP request.
         */
        get () {
            return client.get('/benefits').then(
                response => response.data
            )
        },

        /**
         * Store a benefit.
         *
         * @param data
         *
         * @returns {Promise}
         */
        store (data) {
            return client.post('/benefits', data).then(response => {
                return response.data
            })
        },

        /**
         * Update benefit.
         * @returns {Promise} Promise object represents the HTTP request.
         */
        update (data) {
            return client.put('/benefits/' + data.id, data).then(
                response => response.data
            )
        },

        delete(id) {
            return client.delete('/benefits/' + id).then(
                response => {
                    return response.data
                }
            )
        }
    }
}
