<template>
    <button
        @click="$emit('openSideBar')"
        type="button"
        class="px-8 border-l border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-deeppurple-dark lg:hidden"
    >
        <span class="sr-only">Open sidebar</span>
        <!-- Heroicon name: menu-alt-2 -->
        <font-awesome-icon :icon="['fas', 'bars']" :class="'text-2xl text-deeppurple-dark'" />
    </button>
</template>

<script>
export default {
    name: 'BurgerMenu'
}
</script>
