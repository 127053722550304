<template>
    <div
        class="fixed flex justify-center items-center inset-0 z-50 bg-gray-600 bg-opacity-75 overflow-y-auto py-12"
        @click.self="$emit('close-confirmation-modal')"
    >
        <!-- Modal -->
        <div class="block mx-4 md:max-w-xl max-w-full px-10 py-6 bg-white rounded-lg shadow-xl bg-opacity-100">
            <section class="max-h-80vh flex flex-col justify-center items-center">
                <p class="font-semibold text-deeppurple-dark text-base text-center -mt-3">{{ question }}</p>
            </section>
            <div class="buttons flex justify-center items-center">
                <button
                    @click="$emit('close-confirmation-modal')"
                    type="button"
                    class="px-5 py-1 bg-gray-700 rounded-2xl text-white hover:bg-gray-500 mr-2"
                >
                    Annuler
                </button>
                <button
                    @click="$emit('validate-action', {id: object.id})"
                    type="button"
                    class="px-5 py-1 bg-validation-dark rounded-2xl text-white hover:bg-validation-regular hover:text-white"
                >
                    Valider
                </button>
            </div>
        </div>
        <!-- End of Modal -->
    </div>
</template>

<script>
export default {
    props: ['question', 'object']
}
</script>
