import api from '@/clients/api'

export default {
    namespaced: true,
    state: {
        chartData: {},
        options: {},
        sales: {},
        totalRevenues: 0
    },
    getters: {
        sales: state => state.sales,
        chartOptions: state => state.options,
        chartData: state => state.chartData
    },
    actions: {
        loadStats ({ commit }, payload) {
            return api.post('/tenants/statistics/sales/months', payload).then(response => {
                commit('SET_STATS', response.data)
            }).catch(error => {
                commit('SET_STATS', [])
                throw new Error(`API ${error}`)
            })
        },
        loadSales ({ commit }, payload) {
            return api.post('/tenants/statistics/sales/year', payload).then(response => {
                commit('SET_SALES_STATS', response.data.data)
            }).catch(error => {
                throw new Error(`API ${error}`)
            })
        },
        fetchCustomerInvoicesTotalRevenueByYear({ commit }, payload){
            return api.post('/statistics/invoices/total-revenue/year', payload).then(response => {
                commit('SET_CUSTOMER_INVOICES_TOTAL_REVENUE_YEAR', response.data)
            })
        }
    },
    mutations: {
        SET_STATS (state, statistics) {
            state.chartData = {
                labels: ['Janv.', 'Fév.', 'Mars', 'Avril', 'Mai', 'Juin', 'Juil.', 'Août', 'Sept.', 'Oct.', 'Nov.', 'Déc.'],
                datasets: [{
                    label: '€',
                    barThickness: 16,
                    borderWidth: 0,
                    minBarLength: 0,
                    backgroundColor: [
                        '#490A7D',
                        '#490A7D',
                        '#490A7D',
                        '#490A7D',
                        '#490A7D',
                        '#490A7D',
                        '#490A7D',
                        '#490A7D',
                        '#490A7D',
                        '#490A7D',
                        '#490A7D',
                        '#490A7D'
                    ],
                    hoverBackgroundColor: [
                        '#b085f5',
                        '#b085f5',
                        '#b085f5',
                        '#b085f5',
                        '#b085f5',
                        '#b085f5',
                        '#b085f5',
                        '#b085f5',
                        '#b085f5',
                        '#b085f5',
                        '#b085f5',
                        '#b085f5'
                    ],
                    data: [
                        { y: statistics[1] },
                        { y: statistics[2] },
                        { y: statistics[3] },
                        { y: statistics[4] },
                        { y: statistics[5] },
                        { y: statistics[6] },
                        { y: statistics[7] },
                        { y: statistics[8] },
                        { y: statistics[9] },
                        { y: statistics[10] },
                        { y: statistics[11] },
                        { y: statistics[12] }
                    ]
                }]
            }
            state.options = {
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            fontColor: '#000'
                        },
                        gridLines: {
                            display: true,
                            borderDash: [4, 8],
                            offsetGridLines: false,
                            drawBorder: false,
                            zeroLineBorderDash: [4, 8]
                        }
                    }],
                    xAxes: [{
                        ticks: {
                            padding: 8,
                            fontColor: '#000'
                        },
                        gridLines: {
                            display: true,
                            color: '#39A2AE33',
                            drawBorder: false,
                            offsetGridLines: false,
                            lineWidth: 16,
                            tickMarkLength: 0
                        }
                    }]
                },
                legend: {
                    display: false
                },
                responsive: true,
                maintainAspectRatio: false
            }
        },
        SET_SALES_STATS (state, sales) {
            state.sales = sales
        }
    },
}
