<template>
    <div class="inline-block">
        <button @click="$emit('addFile')">
            <div class="w-44 h-44 max-w-xs flex flex-col justify-center items-center overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out">
                <!-- img add -->
                <div class="m-2">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" class="w-16 h-16 fill-current text-deeppurple-dark">
                    <path d="M32 8.21A23.79 23.79 0 1055.79 32 23.814 23.814 0 0032 8.21zm9.29 25.29H33.5v7.79a1.5 1.5 0 01-3 0V33.5h-7.79a1.5 1.5 0 010-3h7.79v-7.79a1.5 1.5 0 013 0v7.79h7.79a1.5 1.5 0 010 3z"/>
                    </svg>
                </div>
                <!-- button name -->
                <div class="font-sans font-bold text-base text-deeppurple-dark">Créer {{ fileType.add }}</div>
            </div>
        </button>
    </div>
</template>

<script>
export default {
    name: 'Card',
    props: ['fileType']
}
</script>
