<template>
    <div class="px-10">
        <!-- Content Header (Page header) -->
        <section class="content-header mt-10 text-grey-dark">
            <div class="space-y-3 mb-3">
                <div
                    class="flex sm:flex-row flex-col-reverse md:items-center items-start rounded-md sm:space-x-3 space-x-0 sm:text-base text-sm">
                    <!-- BOUTON AJOUTER CLIENTS qui ouvre une modal-->
                    <button type="button"
                            class="projeo-btn-deeppurple md:text-lg sm:mb-0 mb-3 text-base font-medium pl-5 pr-8 py-2
                            rounded-xl md:flex hidden"
                            @click="addCustomer">
                        + Ajouter un client
                    </button>
                </div>
            </div>
        </section>
        <section class="content-header mt-10 text-grey-dark">
            <div class="flex flex-col md:flex-row justify-between">
                <!-- TITRE DE LA PAGE -->
                <div class="flex flex-row items-center mb-3">
                    <h1 class="text-xl xl:text-2xl font-sans font-semibold mb-1">Clients</h1>
                </div>
            </div>

            <!-- LISTE DES CLIENTS -->
            <div v-if="customers.length > 0" class="mb-10">
                <table class="w-full table-auto xl:table-fixed border-separate table-spacing text-sm">
                    <thead class="bg-grey-light text-deeppurple-dark overflow-hidden">
                    <tr class="font-bold rounded-lg shadow-md">
                        <th scope="col" class="py-4 pl-8 text-left">ID</th>
                        <th scope="col" class="py-4 pl-8 xl:w-1/4 w-auto text-left">Nom</th>
                        <th scope="col" class="py-4 text-center">Numéro de téléphone</th>
                        <th scope="col" class="py-4 text-center">Status</th>
                        <th scope="col" class="py-4 text-center w-1/5">Actions</th>
                    </tr>
                    </thead>
                    <tbody class="bg-white">
                    <tr v-for='customer in customers' :key="customer.id" class="text-grey-dark rounded-lg shadow-md">
                        <td class="py-3 pl-8 text-left rounded-l-lg">{{ customer.user.id }}</td>
                        <td class="py-3 pl-8 text-left rounded-l-lg w-1/3">{{ customer.user.name }}</td>
                        <td class="py-3 text-center">{{ customer.user.phone_number }}</td>
                        <td class="py-3 text-center" v-if="customer.user.is_blocked === true">
                            <span
                                class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100
                                text-red-800"
                            >
                                Oui
                            </span>
                        </td>
                        <td class="py-3 text-center" v-else>
                            <span
                                class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                                bg-green-100 text-green-800"
                            >
                                Non
                            </span>
                        </td>
                        <td v-if="customer.deleted_at" class="pb-2 pt-3 flex justify-center items-center rounded-r-lg">
                            <button @click.prevent.stop="askConfirm(customer)" type="button"
                                    class="inline-flex flex-col items-center mx-2 group">
                                <font-awesome-icon :icon="['fas', 'trash-restore']" :style="{ color: '#06C951' }"/>
                                <span
                                    class="text-2xs leading-3 opacity-0 group-hover:opacity-100 transition duration-150 ease">Restaurer</span>
                            </button>
                        </td>
                        <td v-else class="pb-2 pt-3 flex justify-center items-center rounded-r-lg space-x-1">
                            <router-link :to="{ name: 'CustomersDetails', params: { id: customer.id } }"
                                         class="inline-flex flex-col items-center group" type="button">
                                <font-awesome-icon :icon="['fas', 'eye']" :style="{ color: '#490A7D' }"/>
                                <span
                                    class="text-2xs leading-3 opacity-0 group-hover:opacity-100 transition duration-150 ease">Visualiser</span>
                            </router-link>
                            <button @click="editCustomer(customer)" type="button"
                                    class="inline-flex flex-col items-center group">
                                <font-awesome-icon :icon="['fas', 'edit']" :style="{ color: '#FFA400' }"/>
                                <span
                                    class="text-2xs leading-3 opacity-0 group-hover:opacity-100 transition duration-150 ease">Modifier</span>
                            </button>
                            <button @click="askConfirm(customer)" type="button"
                                    class="inline-flex flex-col items-center group">
                                <font-awesome-icon :icon="['fas', 'trash']" :style="{ color: '#ba000d' }"/>
                                <span
                                    class="text-2xs leading-3 opacity-0 group-hover:opacity-100 transition duration-150 ease">Supprimer</span>
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div v-else>
                <ul class="mb-3">
                    <li class="bg-white rounded-xl shadow-md overflow-hidden px-4 py-4 flex justify-around items-center space-x-4 sm:px6 sm:rounded-md">
                        <div class="text-left font-body font-bold text-gray-900">
                            Vous n'avez pas de clients.
                        </div>
                    </li>
                </ul>
            </div>
        </section>

        <section class="content-header mt-10 text-grey-dark">
            <div class="flex flex-col md:flex-row justify-between">
                <!-- TITRE DE LA PAGE -->
                <div class="flex flex-row items-center mb-3">
                    <h1 class="text-xl xl:text-2xl font-sans font-semibold mb-1">Clients en attente</h1>
                </div>
            </div>

            <div v-if="pendingCustomers.length > 0">
                <table class="w-full table-auto xl:table-fixed border-separate table-spacing text-sm">
                    <thead class="bg-grey-light text-deeppurple-dark overflow-hidden">
                        <tr class="font-bold rounded-lg shadow-md">
                            <th scope="col" class="py-4 pl-8 text-left">ID</th>
                            <th scope="col" class="py-4 pl-8 xl:w-1/4 w-auto text-left">Nom</th>
                            <th scope="col" class="py-4 text-center">Numéro de téléphone</th>
                            <th scope="col" class="py-4 text-center">Status</th>
                            <th scope="col" class="py-4 text-center w-1/5">Actions</th>
                        </tr>
                    </thead>
                    <tbody class="bg-white">
                        <tr v-for='customer in pendingCustomers' :key="customer.id" class="text-grey-dark rounded-lg shadow-md">
                            <td class="py-3 pl-8 text-left rounded-l-lg">{{ customer.user.id }}</td>
                            <td class="py-3 pl-8 text-left rounded-l-lg w-1/3">{{ customer.user.name }}</td>
                            <td class="py-3 text-center">{{ customer.user.phone_number }}</td>
                            <td class="py-3 text-center">
                                <span
                                    class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100
                                    text-yellow-800"
                                >
                                    En attente d'acceptation de l'invitation
                                </span>
                            </td>
                            <td class="pb-2 pt-3 flex justify-center items-center rounded-r-lg space-x-1">
                                <button
                                    @click="askConfirm(customer.user)"
                                    type="button"
                                    class="inline-flex flex-col items-center group"
                                >
                                    <font-awesome-icon :icon="['fas', 'trash']" :style="{ color: '#ba000d' }"/>
                                    <span
                                        class="text-2xs leading-3 opacity-0 group-hover:opacity-100 transition
                                        duration-150 ease"
                                    >
                                        Supprimer
                                    </span>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div v-else>
                <ul class="mb-3">
                    <li class="bg-white rounded-xl shadow-md overflow-hidden px-4 py-4 flex justify-around items-center space-x-4 sm:px6 sm:rounded-md">
                        <div class="text-left font-body font-bold text-gray-900">
                            Vous n'avez pas de clients en attente.
                        </div>
                    </li>
                </ul>
            </div>
        </section>

        <!-- MODAL ADD CUSTOMER -->
        <router-view name="modalCustomer"/>

        <transition name="fade">
            <base-modal
                v-if="showModal"
                @close-modal="closeModal"
                :is-edition="this.isEdition"
                @save="save(customer)"
                @update="updateCustomer(customer)"
                modal-title="Ajouter un client"
                description="Ajouter vos clients afin de leur permettre de prendre rendez-vous avec vous."
            >
                <form>
                    <div class="mb-5">
                        <label for="name" class="block text-sm font-medium text-gray-700">
                            Prénom du client
                            <span class="text-red-500 ml-1">*</span></label>
                        <div class="mt-2">
                            <input
                                v-model="customer.first_name"
                                type="text"
                                name="first_name"
                                id="first_name"
                                maxlength="255"
                                class="block w-full px-3 py-2 border focus:border-deeppurple-light focus:bg-purple-100
                                focus:outline-none shadow rounded-md"
                            >
                        </div>
                        <p v-if="errors && errors.first_name" class="text-red-400 italic text-xs">
                            {{ errors.first_name[0] }}
                        </p>
                    </div>
                    <div class="mb-5">
                        <label for="phone_number" class="block text-sm font-medium text-gray-700">
                            Numéro de téléphone<span class="text-red-500 ml-1">*</span></label>
                        <div class="mt-2">
                            <input
                                v-model="customer.phone_number"
                                type="text"
                                name="phone_number"
                                id="phone_number"
                                maxlength="20"
                                class="block w-full px-3 py-2 border focus:border-deeppurple-light focus:bg-purple-100
                                focus:outline-none shadow rounded-md"
                            >
                        </div>
                        <p v-if="errors && errors.phone_number" class="text-red-400 italic text-xs">
                            {{ errors.phone_number[0] }}
                        </p>
                    </div>
                    <div class="mb-5">
                        <label for="email" class="block text-sm font-medium text-gray-700">
                            Adresse email<span class="text-red-500 ml-1">*</span></label>
                        <div class="mt-2">
                            <input
                                v-model="customer.email"
                                type="email"
                                name="email"
                                id="email"
                                class="block w-full px-3 py-2 border focus:border-deeppurple-light focus:bg-purple-100
                                focus:outline-none shadow rounded-md"
                            >
                        </div>
                        <p v-if="errors && errors.email" class="text-red-400 italic text-xs">
                            {{ errors.email[0] }}
                        </p>
                    </div>
                </form>
            </base-modal>
        </transition>
        <transition name="fade">
            <!-- CONFIRMATION MODAL -->
            <ConfirmModal
                v-show="showValidationModal"
                :object="customer"
                @close-confirmation-modal="closeConfirmationModal"
                @validate-action="deleteCustomer"
                ref="confirmModal"
                question="Voulez-vous vraiment supprimer ce client ?"
            />
        </transition>
    </div>
</template>

<script>
import api from '@/clients/api'
import BaseModal from '@/components/BaseModal'
import ConfirmModal from '../../components/Customers/Modal/ConfirmModal'

export default {
    metaInfo: {title: 'Mes clients | Projeo.io'},
    components: {
        BaseModal,
        ConfirmModal
    },
    data () {
        return {
            customer: {
                id: '',
                first_name: '',
                email: '',
                phone_number: ''
            },
            isDisplay: false,
            isFilter: false,
            isEdition: false,
            showForm: false,
            showConfirm: false,
            showValidation: false,
            modalIsOpen: false,
            addModalIsOpen: false,
            deletedRequest: '',
            searchRequest: '',
            modalTitle: '',
            id: '',
            filterChoice: null,
        }
    },
    props: {
        errors: Object
    },
    computed: {
        customers () {
            return this.$store.state.customers.customers
        },
        pendingCustomers () {
            return this.$store.state.customers.customersPendingInvitation
        },
        totalRevenue () {
            return this.$store.state.statistics.totalRevenues
        },
        showModal () {
            return this.$store.state.modale.showModal
        },
        showValidationModal () {
            return this.$store.state.modale.showValidationModal
        },
    },
    mounted () {
        this.$store.dispatch('customers/getCustomers')
        this.$store.dispatch('customers/getCustomersPending')
    },
    methods: {
        addCustomer () {
            this.$store.commit('modale/toggleModal')
            this.modalTitle = 'Ajouter un client'
            this.modalIsOpen = true
        },
        resetFields () {
            this.customer = {
                id: '',
                    first_name: '',
                    email: '',
                    phone_number: ''
            }
            this.errors = {}
        },
        editCustomer (customer) {
            this.isEdition = true
            this.modalTitle = 'Modifier un client'
            this.addModalIsOpen = true
            this.customer = customer
        },
        askConfirm (customer) {
            this.customer = customer
            this.$store.commit('modale/toggleValidationModal')
        },
        save (customer) {
            const formData = new FormData()
            formData.append('first_name', customer.first_name)
            formData.append('email', customer.email)
            formData.append('phone_number', customer.phone_number)

            this.$store.dispatch('customers/create', {vm: this, formData})
            this.$store.commit('modale/toggleModal')
            this.resetFields()
        },
        updateCustomer (customer) {
            const data = {
                'id': customer.id,
                'name': customer.name,
                'description': customer.description,
                'price': customer.price
            }

            this.$store.dispatch('benefits/update', {vm: this, data})
            this.$store.commit('modale/toggleModal')
            this.resetFields()
        },
        closeModal () {
            this.resetFields()
            this.$store.commit('modale/toggleModal')
        },
        closeConfirmationModal () {
            this.$store.commit('modale/toggleValidationModal')
        },
        deleteCustomer (customerId) {
            this.$store.dispatch('customers/deleteCustomer', customerId)
        },
        restoreCustomer (customerId) {
            api.put('/customers/' + customerId + '/restore')
                .then(result => {
                    this.$store.dispatch('customers/getCustomers')
                    this.$toasted.success(result.data.message, {
                        position: 'top-center',
                        duration: 2000
                    })
                })
                .catch(error => {
                    this.$toasted.error(error.response.data.message, {
                        position: 'top-center',
                        duration: 2000
                    })
                    throw new Error(`API ${error}`)
                })
        },
    }
}
</script>
