<template>
    <section class="flex flex-col flex-grow items-center justify-between border-r pt-5 pb-4 border-grey-dark bg-grey-dark w-56">
        <div class="w-full flex flex-col">
            <div class="flex flex-col items-center flex-shrink-0 mb-6">
                <img class="h-16 w-auto bg-blue" src="/img/layout/default.png" alt="Avatar Projeo">
                <!-- Bouton pour ouvrir la modale Mon Compte -->
                <button
                    type="button"
                    class="hover:bg-gray-50 hover:text-gray-900 px-3 py-1 mt-3 font-medium rounded-md focus:outline-none text-white text-sm"
                    @click="$emit('openModal')">
                    Mon compte
                </button>
                <!-- Fin du bouton -->
            </div>
            <nav class="font-medium">
                <ul class="space-y-4 text-sm text-white">
                    <li>
                        <router-link :to="{name: 'Dashboard'}" class="w-full inline-flex items-center relative pl-14 border-l-4 border-transparent hover:bg-grey-hover py-2">
                            <font-awesome-icon :icon="['fas', 'home']" :class="'text-xl absolute left-6'" />
                            Tableau de bord
                        </router-link>
                    </li>

                    <li>
                        <router-link :to="{name: 'Customers'}" class="w-full inline-flex items-center relative pl-14 border-l-4 border-transparent hover:bg-grey-hover py-2">
                            <font-awesome-icon :icon="['fas', 'user']" :class="'text-xl absolute left-6'" />
                            <span class="text-white">Mes clients</span>
                        </router-link>
                    </li>

                    <li>
                        <router-link :to="{name: 'Calendar'}" class="w-full inline-flex items-center relative pl-14 border-l-4 border-transparent hover:bg-grey-hover py-2">
                            <font-awesome-icon :icon="['fas', 'columns']" :class="'text-xl absolute left-6'" />
                            <span class="text-white">Agenda</span>
                        </router-link>
                    </li>

                    <li>
                        <router-link :to="{name: 'Availabilities'}" class="w-full inline-flex items-center relative pl-14 border-l-4 border-transparent hover:bg-grey-hover py-2">
                            <font-awesome-icon :icon="['fas', 'calendar-alt']" :class="'text-xl absolute left-6'" />
                            <span class="text-white">Mes disponibilités</span>
                        </router-link>
                    </li>

                    <li>
                        <router-link :to="{name: 'AppointmentTypes'}" class="w-full inline-flex items-center relative pl-14 border-l-4 border-transparent hover:bg-grey-hover py-2">
                            <font-awesome-icon :icon="['fas', 'user-clock']" :class="'text-xl absolute left-6'" />
                            <span class="text-white">Types de rendez-vous</span>
                        </router-link>
                    </li>

                    <li>
                        <router-link :to="{name: 'Benefits'}" class="w-full inline-flex items-center relative pl-14 border-l-4 border-transparent hover:bg-grey-hover py-2">
                            <font-awesome-icon :icon="['fas', 'concierge-bell']" :class="'text-xl absolute left-6'" />
                            <span class="text-white">Prestations</span>
                        </router-link>
                    </li>
                </ul>
            </nav>
        </div>
        <div class="w-full flex flex-col items-center">
            <div class="line boder-2 border-white opacity-10 border-solid border-t-2 mb-4 w-60"></div>
            <font-awesome-icon :icon="['fas', 'cog']" :class="'text-2xl text-white cursor-pointer'" />
        </div>
    </section>
</template>

<script>
export default {
}
</script>
