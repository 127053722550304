<template>
    <div class="bg-white min-h-screen px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
        <div class="max-w-max mx-auto">
            <main class="sm:flex">
                <p class="text-4xl font-extrabold text-deeppurple-dark sm:text-5xl">403</p>
                <div class="sm:ml-6">
                    <div class="sm:border-l sm:border-gray-200 sm:pl-6">
                        <h1 class="ml-1 text-4xl font-extrabold text-error-regular tracking-tight sm:text-5xl">
                            Adresse email non confirmée
                        </h1>
                        <p class="ml-1 mt-1 text-base text-gray-500">
                            Veuillez cliquer sur le lien envoyé à votre adresse courriel (c.lablancherie@live.fr).
                        </p>
                        <p class="ml-1 mt-1 text-base text-gray-500">
                            N'oubliez pas de vérifier vos spams.
                        </p>
                    </div>
                    <div class="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                        <a
                            href="#"
                            @click="sendValidationEmail"
                            class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium
                            rounded-md shadow-sm text-white projeo-btn-deeppurple hover:bg-deeppurple-hover focus:outline-none
                            focus:ring-2 focus:ring-offset-2 focus:border-deeppurple-dark"
                        >
                            Renvoyer le courriel
                        </a>
                    </div>
                </div>
            </main>
        </div>
    </div>
</template>

<script>

import axios from "axios";

export default {
    methods: {
        sendValidationEmail() {
            let config = {
                headers:  {
                    Authorization: 'Bearer ' + localStorage.getItem('jwt.token')
                }
            }
            axios.post(`${process.env.VUE_APP_URL_API}/email/resend`, {}, config).then(response => {
                this.$toasted.success(response.data.message, {
                    position: 'top-center',
                    duration: 2000
                })
            })
        }
    }
}
</script>
