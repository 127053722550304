<template>
    <section class="py-4 mx-3 2xl:mx-8">
        <div class="relative flex-shrink-0 flex flex-row h-28 shadow-md px-8 py-3 bg-deeppurple-dark rounded-xl
                justify-between">
            <div class="flex flex-col py-1">
                <p class="text-white text-lg my-auto">Bonjour,</p>
                <p class="text-grey-regular my-auto">Bienvenue dans votre espace personnel Projeo. Dans cet espace
                    vous trouverez l'ensemble des devis et factures élaborées par votre artisan.</p>
            </div>
            <div class="2xl:absolute 2xl:-bottom-5 2xl:right-24 w-24 lg:w-28">
                <img src="/img/layout/group.png" alt="">
            </div>
        </div>
        <div class="grid grid-cols-2 gap-4 py-5">
            <table
                class="w-full table-auto xl:table-fixed border-collapse text-sm shadow-md border border-gray-100 border-opacity-60">
                <thead class="space-y-3 bg-grey-light rounded-lg">
                <tr class="font-bold shadow-md ">
                    <th scope="col" class="py-3 pl-8 xl:w-1/4 w-auto text-left">Devis</th>
                    <th scope="col" class="py-3 pl-8 xl:w-1/4 w-auto text-left">Numéro du devis</th>
                    <th scope="col" class="py-3 pl-8 xl:w-1/4 w-auto text-left">Montant TTC</th>
                    <th scope="col" class="py-3 pl-8 xl:w-1/4 w-auto text-left">Status</th>
                </tr>
                </thead>
                <tbody class="bg-white divide-y divide-grey-200">
                <tr>
                    <td class="py-3 px-8 text-left rounded-l-lg w-1/3 ">"17/03/21"</td>
                    <td class="py-3 px-8 text-center ">Devis n°56032021</td>
                    <td class="py-3 px-8 text-center">2345 €</td>
                    <td class="py-3 px-8 text-center">
                        <span
                            class="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 bg-yellow-400 rounded-full">En attente</span>
                    </td>
                </tr>
                <tr>
                    <td class="py-3 px-8 pl-8 text-left rounded-l-lg w-1/3">"17/03/21"</td>
                    <td class="py-3 px-8 text-center">Devis n°56032021</td>
                    <td class="py-3 px-8 text-center">2345 €</td>
                    <td class="py-3 px-8 italic text-center">Accepté</td>
                </tr>
                <tr>
                    <td class="py-3 px-8 text-left rounded-l-lg w-1/3">"17/03/21"</td>
                    <td class="py-3 px-8 text-center ">Devis n°56032021</td>
                    <td class="py-3 px-8 text-center">2345 €</td>
                    <td class="py-3 px-8 italic text-center">Accepté</td>
                </tr>
                <tr>
                    <td class="py-3 px-8 text-left rounded-l-lg w-1/3">"17/03/21"</td>
                    <td class="py-3 px-8 text-center">Devis n°56032021</td>
                    <td class="py-3 px-8 text-center">2345 €</td>
                    <td class="py-3 px-8 italic text-center">Accepté</td>
                </tr>
                </tbody>
            </table>
            <table
                class="w-full table-auto xl:table-fixed border-collapse text-sm shadow-md border border-gray-100 border-opacity-60">
                <thead class="space-y-3 bg-grey-light rounded-lg">
                <tr class="py-3 text-sm font-bold text-center whitespace-nowrap
                              tracking-wider shadow-md max-h-0">
                    <th scope="col" class="px-6 py-4 text-left">Factures</th>
                    <th scope="col" class="px-6 py-4 text-left">Numéro du devis</th>
                    <th scope="col" class="px-6 py-4 text-left">Montant TTC</th>
                    <th scope="col" class="px-6 py-4 text-left">Status</th>
                </tr>
                </thead>
                <tbody class="bg-white divide-y divide-grey-200">
                <tr>
                    <td class="py-3 px-8 text-left rounded-l-lg w-1/3 ">"17/03/21"</td>
                    <td class="py-3 px-8 text-center ">Devis n°56032021</td>
                    <td class="py-3 px-8 text-center">2345 €</td>
                    <td class="py-3 px-8 text-center ">
                        <span
                            class="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 bg-yellow-400 rounded-full">A Payer</span>
                    </td>
                </tr>
                <tr>
                    <td class="py-3 px-8 pl-8 text-left rounded-l-lg w-1/3">"17/03/21"</td>
                    <td class="py-3 px-8 text-center">Devis n°56032021</td>
                    <td class="py-3 px-8 text-center">2345 €</td>
                    <td class="py-3 px-8 italic text-center">Payée</td>
                </tr>
                <tr>
                    <td class="py-3 px-8 text-left rounded-l-lg w-1/3">"17/03/21"</td>
                    <td class="py-3 px-8 text-center">Devis n°56032021</td>
                    <td class="py-3 px-8 text-center">2345 €</td>
                    <td class="py-3 px-8 italic text-center">Payée</td>
                </tr>
                </tbody>
            </table>
        </div>
    </section>
</template>

<script>
export default {
    name: 'DashboardCustomer.vue'
}
</script>

<style scoped>

</style>
