<template>
    <button
        :disabled="loading"
        class="flex flex-row items-center justify-center text-white bg-deeppurple-dark text-xl rounded-lg shadow-lg bg-white py-3 w-60 lg:w-full">
        <div v-if="loading" class="btn-spinner mr-2" />
        <slot />
    </button>
</template>

<script>
export default {
    name: 'LoadinButton',
    props: {
        loading: Boolean
    }
}
</script>
