<template>
    <div class="bg-deeppurple-dark lg:rounded-l-xl lg:rounded-b-none w-12/12 2xl:w-3/12 xl:w-4/12 lg:w-5/12 text-white px-8 flex flex-col items-center">
        <div class="mb-6 lg:mb-32 mt-6 lg:mt-12">
            <p class="font-black text-3xl leading-tight text-center lg:text-left mb-3 lg:mb-12">Bienvenue sur <span class="whitespace-nowrap">Projeo !</span></p>
            <div class="font-extralight text-center lg:text-justify">Afin de pouvoir créer votre espace, vous devez passer par <strong class="font-semibold">3 petites étapes</strong>. Ne vous inquiétez pas, ça ne sera pas long !</div>
        </div>

        <nav class="flex px-4 text-grey-regular flex-row justify-evenly lg:items-baseline items-center w-full lg:flex-col select-none">
            <div class="flex flex-col lg:flex-row items-center" :class="{ 'text-white' : steps > 0 }">
                <div class="flex justify-center flex-shrink-0 items-center rounded-full border-2 w-8 h-8 text-center" :class="[steps > 0 ? 'border-white' : 'border-grey-regular', steps > 1 ? 'bg-white': '']">
                    <span v-if="steps <= 1">1</span>
                    <svg v-else class="w-5 h-5 text-deeppurple-dark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                    </svg>
                </div>
                <p class="lg:text-left lg:ml-5 lg:my-0 text-center mt-3 mb-5 lg:text-base text-xs">Informations générales</p>
            </div>

            <div class="lg:h-10 h-16 lg:ml-4 ml-0 border-dashed lg:border-l border-t lg:border-t-0 w-full" :class="steps > 1 ? 'border-white' : 'border-grey-regular'"></div>

            <div class="flex flex-col lg:flex-row items-center" :class="{ 'text-white' : steps > 1 }">
                <div class="flex justify-center flex-shrink-0 items-center rounded-full border-2 w-8 h-8 text-center" :class="[steps > 1 ? 'border-white' : 'border-grey-regular', steps > 2 ? 'bg-white': '']">
                    <span v-if="steps <= 2">2</span>
                    <svg v-else class="w-5 h-5 text-deeppurple-dark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                    </svg>
                </div>
                <p class="lg:text-left lg:ml-5 lg:my-0 text-center mt-3 mb-5 lg:text-base text-xs">Votre entreprise</p>
            </div>

            <div class="lg:h-10 h-16 lg:ml-4 ml-0 border-dashed lg:border-l border-t lg:border-t-0 w-full" :class="steps > 2 ? 'border-white' : 'border-grey-regular'"></div>

            <div class="flex flex-col lg:flex-row items-center" :class="{ 'text-white' : steps > 2 }">
                <div class="flex justify-center flex-shrink-0 items-center rounded-full border-2 w-8 h-8 text-center" :class="[steps > 2 ? 'border-white' : 'border-grey-regular', steps > 3 ? 'bg-white': '']">
                    <span v-if="steps <= 3">3</span>
                    <svg v-else class="w-5 h-5 text-deeppurple-dark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                    </svg>
                </div>
                <p class="lg:text-left lg:ml-5 lg:my-0 text-center mt-3 mb-5 lg:text-base text-xs">Statut juridique</p>
            </div>
        </nav>
    </div>
</template>

<script>
export default {
    name: 'breadcrumb',
    props: ['steps']
}
</script>
