<template>
    <div class="px-10">
        <section class="content-header mt-5 text-grey-dark">
            <div class="flex flex-col md:flex-row justify-between">
                <div class="flex flex-row items-center mb-3">
                    <h1 class="text-xl xl:text-2xl font-sans font-semibold mb-1">Mes disponibilités</h1>
                </div>
            </div>

            <div class="space-y-3 mb-3">
                <div
                    class="flex sm:flex-row flex-col-reverse md:items-center items-start rounded-md sm:space-x-3 space-x-0 sm:text-base text-sm">

                    <!-- BOUTON AJOUTER saisie de temps qui ouvre une modal-->
                    <button type="button"
                            class="projeo-btn-deeppurple md:text-lg sm:mb-0 mb-3 text-base font-medium pl-5 pr-8 py-2 rounded-xl md:flex hidden"
                            @click="addAvailability">
                        + Ajouter une disponibilité
                    </button>
                </div>
            </div>
        </section>

        <section class="flex flex-col text-sm flex-1 justify-between">
            <div v-if="availabilities.length">
                <table class="w-full table-fixed border-separate table-spacing">
                    <thead class="bg-grey-light text-deeppurple-dark">
                    <tr class="font-bold rounded-lg shadow-md">
                        <th scope="col" class="py-4">Début</th>
                        <th scope="col" class="py-4">Fin</th>
                        <th scope="col" class="py-4">Status</th>
                        <th scope="col" class="py-4">Rendez-vous pris</th>
                        <th scope="col" class="py-4">Actions</th>
                    </tr>
                    </thead>
                    <tbody class="bg-white">
                    <tr v-for="availability in availabilities" :key="availability.id"
                        class="text-grey-dark text-center rounded-lg shadow-md p-2">
                        <td class="py-2">{{ availability.starting_at }}</td>
                        <td class="py-2">{{ availability.ending_at }}</td>
                        <td class="py-2">
                            <span
                                class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                                bg-red-100 text-red-800"
                                v-if="availability.is_unreservable === 1"
                            >
                                Verrouillé
                            </span>
                            <span
                                class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                                bg-green-100 text-green-800"
                                v-else
                            >
                                Disponible
                            </span>
                        </td>
                        <td class="py-2">
                            <span
                                class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                                bg-red-100 text-red-800"
                                v-if="availability.appointment !== null"
                            >
                                Rendez-vous pris
                            </span>
                            <span
                                class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                                bg-green-100 text-green-800"
                                v-else
                            >
                                Disponible
                            </span>
                        </td>
                        <td class="py-2">
                            <button @click.prevent.stop="askConfirm(availability)" type="button"
                                    class="inline-flex flex-col items-center mx-2 group">
                                <font-awesome-icon :icon="['fas', 'trash']" :style="{ color: '#ba000d' }"/>
                                <span
                                    class="text-2xs leading-3 opacity-0 group-hover:opacity-100 transition duration-150 ease">Supprimer</span>
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div v-if="availabilities.length === 0" class="flex">
                <p class="bg-grey-light text-deeppurple-dark font-medium rounded-lg shadow-md mx-auto px-8 py-3">
                    Vous n'avez pas encore saisi de disponibilités.
                </p>
            </div>
        </section>

        <transition name="fade">
            <base-modal
                v-if="showModal"
                @close-modal="closeModal"
                :is-edition="this.isEdition"
                @save="save(availability)"
                @update="updateBenefit(availability)"
                modal-title="Ajouter une période de disponibilité"
                description="Une période de disponibilité va vous permettre de créer des créneaux horaires que vos
                clients pourront sélectionner pour prendre rendez-vous avec vous."
            >
                <form>
                    <div class="grid grid-cols-2 mx-auto">
                        <div class="w-full">
                            <label for="startingAt" class="block text-sm font-medium text-gray-700">
                                Début
                                <span class="text-red-500 ml-1">*</span>
                            </label>
                            <div class="mt-2 w-full">
                                <the-date-picker id="startingAt" :date-prop="availability.starting_at" @setDate="availability.starting_at = $event"></the-date-picker>
                            </div>
                            <p v-if="errors && errors.starting_at" class="text-red-400 italic text-xs">{{ errors.starting_at[0] }}</p>
                        </div>
                        <div class="w-full">
                            <label for="endingAt" class="block text-sm font-medium text-gray-700">
                                Fin
                                <span class="text-red-500 ml-1">*</span>
                            </label>
                            <div class="mt-2 w-full">
                                <the-date-picker id="endingAt" :date-prop="availability.ending_at" @setDate="availability.ending_at = $event"></the-date-picker>
                            </div>
                            <p v-if="errors && errors.ending_at" class="text-red-400 italic text-xs">{{ errors.ending_at[0] }}</p>
                        </div>

                    </div>
                </form>
            </base-modal>

            <ConfirmModal
                v-show="showValidationModal"
                :object="availability"
                @close-confirmation-modal="closeConfirmationModal"
                @validate-action="deleteAvailability"
                ref="confirmModal"
                question="Voulez-vous vraiment supprimer cette disponibilité ?"
            />
        </transition>
    </div>
</template>

<script>
import BaseModal from '@/components/BaseModal'
import ConfirmModal from '@/Shared/ConfirmModal'
import TheDatePicker from '@/Shared/TheDatePicker'

export default {
    components: {
        BaseModal,
        ConfirmModal,
        TheDatePicker
    },
    computed: {
      availabilities () {
        return this.$store.state.availabilities.availabilities
      },
      showModal () {
        return this.$store.state.modale.showModal
      },
      showValidationModal () {
        return this.$store.state.modale.showValidationModal
      },
    },
    mounted() {
      this.$store.dispatch('availabilities/getAvailabilities')
    },
    data () {
        return {
            isEdition: false,
            currentPage: 1,
            lastPage: 1,
            totalPages: '',
            showForm: false,
            showConfirm: false,
            showValidation: false,
            edition: false,
            modalIsOpen: false,
            toastIsOpen: false,
            modalTitle: '',
            toastMessage: '',
            toastStyle: '',
            availability: {
                id: '',
                starting_at: '',
                ending_at: '',
                is_unreservable: '',
                appointment: ''
            },
            errors: {}
        }
    },
    methods: {
        resetFields () {
            this.availability = {
                id: '',
                starting_at: '',
                ending_at: '',
                is_unreservable: '',
                appointment: ''
            }
            this.errors = {}
        },
        addAvailability () {
            this.$store.commit('modale/toggleModal')
            this.modalTitle = 'Ajouter une disponibilité'
            this.modalIsOpen = true
        },
        save (availability) {
            const formData = new FormData()
            formData.append('starting_at', availability.starting_at)
            formData.append('ending_at', availability.ending_at)

            this.$store.dispatch('availabilities/create', {vm: this, formData})
            this.$store.commit('modale/toggleModal')
            this.resetFields()
        },
        editBenefit (benefit) {
            this.isEdition = true
            this.modalTitle = 'Modifier une prestation'
            this.$store.commit('modale/toggleModal')
            this.benefit = benefit
        },
        updateBenefit (benefit) {
            const data = {
                'id': benefit.id,
                'name': benefit.name,
                'description': benefit.description,
                'price': benefit.price
            }

            this.$store.dispatch('benefits/update', {vm: this, data})
            this.$store.commit('modale/toggleModal')
            this.resetFields()
        },
        askConfirm (availability) {
            this.availability = availability
            this.$store.commit('modale/toggleValidationModal')
        },
        askDelete (id) {
            this.availability.id = id
            this.showConfirm = true
            this.modalIsOpen = true
        },
        closeModal () {
            this.$store.commit('modale/toggleModal')
        },
        closeConfirmationModal () {
            this.$store.commit('modale/toggleValidationModal')
        },
        deleteAvailability (availability) {
            const data = {
                'id': availability.id
            }
            this.$store.dispatch('availabilities/delete', {vm: this, data})
            this.$store.commit('modale/toggleValidationModal')
        }
    }
}
</script>
