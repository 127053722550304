<template>
    <transition name="moveInUp">
        <div>
            <div class="fixed z-10 inset-0 overflow-y-auto">
                <div class="flex items-end justify-center h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <div class="fixed inset-0 transition-opacity" aria-hidden="true">
                        <button @click="closeModale">
                            <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
                        </button>
                    </div>
                    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                    <div
                        class="flex-1 inline-block bg-white rounded-lg px-4 pt-5 pb-4 h-700px text-left shadow-xl transform transition-all
                         sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6"
                        role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                        <div class="h-100%">
                            <div>
                                <div class="hidden sm:block">
                                    <div class="border-b border-gray-200">
                                        <nav class="flex" aria-label="Tabs">
                                            <button @click="changeModalCategories('profile', 'Profile')"
                                                    style="outline: none"
                                                    v-bind:class="{active: modalCategories === 'profile', unactive: modalCategories !== 'profile'}"
                                                    class="hover:text-gray-700 hover:border-gray-300 hover:font-extrabold">
                                                Mon profil
                                            </button>
                                            <button @click="changeModalCategories('securite', 'Security')"
                                                    style="outline: none"
                                                    v-bind:class="{active: modalCategories === 'securite', unactive: modalCategories !== 'securite'}"
                                                    class="hover:text-gray-700 hover:border-gray-300 text hover:font-extrabold">
                                                Sécurité
                                            </button>

                                            <button @click="changeModalCategories('abonnement', 'Subscription')"
                                                    style="outline: none"
                                                    v-bind:class="{active: modalCategories === 'abonnement', unactive: modalCategories !== 'abonnement'}"
                                                    class="hover:text-gray-700 hover:border-gray-300 hover:font-extrabold">
                                                Mon abonnement
                                            </button>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                            <div class="h-100%">
                                <router-view name="modal"></router-view>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>

</template>

<script>
export default {
    name: 'MonCompteModal',

    data () {
        return {
            modalCategories: null
        }
    },
    created () {
        this.modalCategories = this.$route.meta.categorie
    },
    methods: {
        closeModale () {
            this.changeModalCategories('profile', 'Dashboard')
        },
        changeModalCategories (cat, router) {
            this.modalCategories = cat
            this.$router.push({ name: router })
        }
    }
}
</script>
<style>
.active {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
    width: 33%;
    padding: 0.5rem 1rem;
    text-align: center;
    font-weight: 700;
    border-bottom-width: 2px;
    font-size: 0.875rem;
    line-height: 1.25rem;
}

.unactive {
    border-color: transparent;
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
    width: 33%;
    padding: 0.5rem 1rem;
    text-align: center;
    border-bottom-width: 2px;
    font-size: 0.875rem;
    line-height: 1.25rem;
}
</style>
