import { availabilityRepository } from '@/container'

export default {
    namespaced: true,
    state: {
        availabilities: []
    },
    getters: {},
    actions: {
        getAvailabilities ({ commit }) {
            availabilityRepository().get().then(response => {
                commit('SET_AVAILABILITIES', response)
            })
        },
        create ({ commit }, {vm, formData}) {
            availabilityRepository().store(formData).then(response => {
                response.availabilities.forEach((availability) => {
                    commit('ADD_AVAILABILITY', availability)
                })
                vm.$toasted.success(response.message, {
                    position: 'top-center',
                    duration: 2000
                })
            }).catch(error => {
                throw new Error(`API ${error}`)
            })
        },
        update ({ commit }, {vm, data}) {
            availabilityRepository().update(data).then(response => {
                commit('UPDATE_AVAILABILITY', response.appointmentType, data.id)
                vm.$toasted.success(response.message, {
                    position: 'top-center',
                    duration: 2000
                })
            }).catch(error => {
                throw new Error(`API ${error}`)
            })
        },
        delete ({ commit }, {vm, data}) {
            availabilityRepository().delete(data.id).then(response => {
                commit('DELETE_AVAILABILITY', data.id)
                vm.$toasted.success(response.message, {
                    position: 'top-center',
                    duration: 2000
                })
            }).catch(error => {
                throw new Error(`API ${error}`)
            })
        },
    },
    mutations: {
        ADD_AVAILABILITY (state, availability) {
            state.availabilities.push(availability)
        },
        UPDATE_AVAILABILITY (state, availability, id) {
            const index = state.availabilities.findIndex(availability => availability.id === id)
            state.availabilities[index] = availability
        },
        DELETE_AVAILABILITY (state, id) {
            const index = state.availabilities.findIndex(availability => availability.id === id)
            state.availabilities.splice(index, 1)
        },
        SET_AVAILABILITIES (state, data) {
            state.availabilities = data.data
        }
    }
}
