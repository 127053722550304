<template>
    <section class="flex flex-col h-full text-sm flex-1 justify-between">
        <div class="w-full relative rounded-lg shadow-md bg-grey-light">
            <!-- Top right button -->
            <div class="absolute right-0 mt-2"><menu-button :isDownload="false" :isDeleted="isDeleted" @edit="askEdit" @confirm="askConfirm" /></div>
            <div class="mx-5 mt-3 mb-5 font-sans">
                <!-- Title -->
                <h3 class="mb-3 text-grey-dark font-bold text-base">Informations générales</h3>
                <div class="my-2">
                    <label class="text-grey-regular">Prénom NOM</label>
                    <p class="text-blacktext-regular">{{ customer.name }}</p>
                </div>
                <div class="my-2">
                    <label class="text-grey-regular">Courriel</label>
                    <p class="text-blacktext-regular">{{ customer.email }}</p>
                </div>
                <div class="my-2 inline-flex space-x-10">
                    <div>
                        <label class="text-grey-regular">Adresse postale</label>
                        <p v-if="customer.street_number && customer.street_address" class="text-blacktext-regular">{{ customer.street_number }}, {{ customer.street_address }}</p>
                        <p v-else class="text-blacktext-regular">-</p>
                    </div>
                    <div>
                        <label class="text-grey-regular">Code postal</label>
                        <p class="text-blacktext-regular">{{ customer.zip }}</p>
                    </div>
                </div>
                <div class="my-2">
                    <label class="text-grey-regular">Pays</label>
                    <p v-if="customer.country" class="text-blacktext-regular">{{ customer.country.name }}</p>
                    <p v-else class="text-blacktext-regular">-</p>
                </div>
                <div class="my-2">
                    <label class="text-grey-regular">Numéro de téléphone fixe</label>
                    <p v-if="customer.phone_number" class="text-blacktext-regular">{{ customer.phone_number }}</p>
                    <p v-else class="text-blacktext-regular">-</p>
                </div>
                <div class="my-2">
                    <label class="text-grey-regular">Numéro de mobile</label>
                    <p v-if="customer.mobile_number" class="text-blacktext-regular">{{ customer.mobile_number }}</p>
                    <p v-else class="text-blacktext-regular">-</p>
                </div>
                <div class="my-2">
                    <label class="text-grey-regular">Langue de communication</label>
                    <p class="text-blacktext-regular">{{ customer.language.name }}</p>
                </div>
                <div class="my-2">
                    <label class="text-grey-regular">Devise de paiement</label>
                    <p class="text-blacktext-regular">{{ customer.currency.symbol }}</p>
                </div>
                <div class="my-2">
                    <label class="text-grey-regular">Nom d'entreprise</label>
                    <p v-if="customer.company_name" class="text-blacktext-regular">{{ customer.company_name }}</p>
                    <p v-else class="text-blacktext-regular">-</p>
                </div>
                <div class="my-2">
                    <label class="text-grey-regular">Numéro d'entreprise</label>
                    <p v-if="customer.company_number" class="text-blacktext-regular">{{ customer.company_number }}</p>
                    <p v-else class="text-blacktext-regular">-</p>
                </div>
                <div class="my-2">
                    <label class="text-grey-regular">Statut</label>
                    <dd class="mt-1">
                        <span v-if="customer.deleted_at" class="px-5 py-0.5 text-xs font-medium rounded-full bg-error-regular text-white">Inactif</span>
                        <span v-else class="px-5 py-0.5 text-xs font-medium rounded-full bg-validation-regular text-white">Actif</span>
                    </dd>
                </div>
                <!-- Paiements -->
                <div class="mt-7 mb-3">
                    <h3 class="text-grey-dark font-bold text-base">Paiements</h3>
                    <!-- diagramme -->
                    <div class="chart w-full bg-white shadow-md rounded-lg mt-2 px-6 py-4">
                        <Charts></Charts>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import MenuButton from '@/components/Customers/MenuButton'
import Charts from '../Charts/Charts.vue'

export default {
    name: 'CustomerInformations',
    metaInfo: { title: 'Détail client | Projeo.io' },
    components: {
        MenuButton,
        Charts
    },
    props: ['customer', 'isDeleted'],
    methods: {
        askEdit () {
            this.$emit('edit', this.customer)
        },
        askConfirm () {
            this.$emit('confirm', this.customer.id)
        },
        askDelete () {
            this.$emit('delete', this.customer.id)
        }
    }
}
</script>
