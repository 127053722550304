<template>
    <div class="flex flex-col place-items-center mt-6">
        <div class="mb-4">
            <div class="mx-auto">
                <img class="w-40 mx-auto" src="/img/layout/logo.png" alt="logo projeo">
            </div>
        </div>
        <form class="bg-white lg:shadow-lg rounded-lg" @submit.prevent="handleSubmit">
            <div class="my-2 lg:my-12 mx-2 lg:mx-12">
                <h1 class="text-deeppurple-dark text-center text-2xl font-bold mb-4 lg:mb-12">
                    Réinitialisation du mot de passe
                </h1>
                <div class="mb-6 flex flex-col items-center justify-center lg:items-start">
                    <label class="text-blacktext-light text-base lg:ml-3">
                        Courriel <span class="required text-error-regular ml-1">*</span>
                    </label>
                    <input
                        type="email"
                        id="email"
                        v-model="form.email"
                        class="mt-2 shadow-lg appearance-none rounded-lg w-full h-60px py-4 px-3
                            text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        placeholder="john@doe.com"
                        required="required"
                    >
                </div>
                <div class="mb-4 flex justify-center">
                    <loadin-button :loading="sending" class="" type="submit">
                        Réinitialiser le mot de passe
                    </loadin-button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import {
    authenticationService
} from '@/container'
import LoadinButton from '@/Shared/LoadinButton'

export default {
    components: {LoadinButton},
    data () {
        return {
            sending: false,
            form: {
                email: '',
                password: '',
                remember: null
            }
        }
    },
    props: {
        errors: Object
    },
    mounted () {
        // if (authenticationService().authenticated) {
        //     this.$router.push({
        //         name: 'Dashboard'
        //     })
        // }
    },
    methods: {
        handleSubmit (e) {
            e.preventDefault()
            if (this.form.email.length > 0) {
                authenticationService()
                    .resetPassword(this.form.email)
                    .then(() => {
                        this.$toasted.success("Mot de passe réinitialiser avec succès, veuillez vérifier vos emails (spams compris) !")
                    })
                    .catch(function (error) {
                        console.error(error.response)
                    })
            }
        },
        goToRegister () {
            return this.$router.push({name: 'StepOne'})
        }
    }
}
</script>

<style scoped>
.wrapper-split span::after,
.wrapper-split span::before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 10.5rem;
    height: 0.08rem;
    background: #50505080;
}

@media (max-width: 1023px) {
    .wrapper-split span::after,
    .wrapper-split span::before {
        width: 6rem;
    }
}

.wrapper-split span::before {
    margin-right: .5rem
}

.wrapper-split span::after {
    margin-left: .5rem
}
</style>
