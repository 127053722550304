<template>
    <div>
        <div v-if="subscription" class="p-10 flex lg:flex-row flex-col flex-1 items-start lg:space-x-6 space-x-0 lg:space-y-0 space-y-6 text-grey-dark">
            <section  class="xl:w-4/12 lg:w-5/12 w-full flex flex-col shadow-md border border-gray-100 border-opacity-60 rounded-lg px-6 pt-5 pb-12 space-y-6">
                <h3 class="font-bold tracking-wide text-lg">Mon abonnement</h3>
                <div class="border-2 border-deeppurple-dark rounded-md">
                    <div class="w-full h-28 flex justify-center items-center text-deeppurple-dark font-black"><span class="text-4xl">XX</span>&nbsp;€&nbsp;TTC</div>
                    <div class="w-full h-28 flex justify-center items-center bg-deeppurple-light bg-opacity-20 font-bold">Avantage N°1</div>
                    <div class="w-full h-28 flex justify-center items-center font-bold">Avantage N°2</div>
                    <div class="w-full h-28 flex justify-center items-center bg-deeppurple-light bg-opacity-20 font-bold">Avantage N°3</div>
                    <div class="w-full h-28 flex justify-center items-center font-bold"></div>
                </div>
            </section>

            <section class="xl:w-8/12 lg:w-7/12 w-full flex flex-col shadow-md border border-gray-100 border-opacity-60 rounded-lg px-8 pt-4 pb-8 space-y-6">
                <h3 class="font-bold tracking-wide text-lg">Mes factures</h3>
                <div class="w-full text-sm">
                    <table class="w-full table-fixed border-separate table-spacing">
                        <thead class="bg-grey-light text-deeppurple-dark">
                            <tr class="font-bold rounded-lg shadow-md">
                                <th scope="col" class="py-4 text-center">N°</th>
                                <th scope="col" class="py-4 text-center">Date</th>
                                <th scope="col" class="py-4 text-center">Montant</th>
                                <th scope="col" class="py-4 text-center w-2/12">Statut</th>
                            </tr>
                        </thead>
                        <tbody class="bg-white">
                            <tr v-for="invoice in invoices" :key="invoice.id" class="text-grey-dark rounded-lg shadow-md">
                                <td class="py-3 text-center rounded-l-lg">{{ invoice.invoice_number }}</td>
                                <td class="py-3 text-center text-xs">{{ invoice.due_date }}</td>
                                <td class="py-3 text-center">{{ invoice.total }}&nbsp;€</td>
                                <td class="py-3 text-center rounded-r-lg">
                                    <span class="px-3 sm:text-xs text-2xs py-1 bg-amber-light rounded-full text-white">{{ invoice.status.name }}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section>
        </div>

        <div v-else class="p-10 flex flex-col flex-1 text-grey-dark space-y-6">
            <h3 class="font-bold tracking-wide text-lg">Mon abonnement</h3>
            <section class="flex flex-col">
                <div class="w-full flex items-center font-semibold text-black md:text-xl text-lg pl-24 space-x-3 my-8">
                    <span :class="{ 'text-deeppurple-dark' : monthly }">1 Mois</span>
                    <button type="button" @click="monthly = !monthly" class="bg-gray-100 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none ring-2 ring-offset-1 ring-deeppurple-dark focus:ring-deeppurple-hover" aria-pressed="false">
                        <span class="sr-only">Use setting</span>
                        <span aria-hidden="true" :class="monthly ? 'translate-x-0' : 'translate-x-5'" class="bg-deeppurple-dark pointer-events-none inline-block h-5 w-5 rounded-full shadow transform ring-0 transition ease-in-out duration-200"></span>
                    </button>
                    <span :class="{ 'text-deeppurple-dark' : !monthly }">1 An</span>
                </div>
                <div class="flex md:flex-row flex-col">
                    <div class="xl:w-4/12 md:w-1/2 xl:mx-8 mx-4 w-full border-2 border-deeppurple-dark rounded-md">
                        <div class="w-full h-28 flex justify-center items-center text-deeppurple-dark font-black"><span class="text-4xl">XX</span>&nbsp;€&nbsp;TTC</div>
                        <div class="w-full h-28 flex justify-center items-center bg-deeppurple-light bg-opacity-20 font-bold">Avantage N°1</div>
                        <div class="w-full h-28 flex justify-center items-center font-bold">Avantage N°2</div>
                        <div class="w-full h-28 flex justify-center items-center bg-deeppurple-light bg-opacity-20 font-bold">Avantage N°3</div>
                        <div class="w-full h-28 flex justify-center items-center font-bold"></div>
                    </div>
                    <div class="xl:w-8/12 md:w-1/2 w-full xl:mx-8 mx-4 md:mt-0 mt-8 md:text-base text-sm text-justify">
                        <h4 class="font-bold text-deeppurple-dark text-lg mb-6">Pourquoi cette offre ?</h4>
                        <p class="mb-4">
                            Mon but à travers Projeo est de partager mes connaissances avec le plus grand nombre, c'est pourquoi j'essaie de rendre un maximum de contenu gratuit et public.
                        </p>
                        <p class="mb-4">
                            Malgré tout, la préparation, l'enregistrement et le montage des tutoriels prend un temps considérable (10 à 20 heures par semaine). Du coup proposer des options payantes, comme le téléchargement des sources, me permet d'amortir une partie du temps passé et de continuer à faire vivre le site.
                        </p>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            subscription: true,
            monthly: true,
            invoices: [
                {
                    id: 11,
                    invoice_number: '13734',
                    due_date: '31/03/2008',
                    terms: 'Cumque velit quasi nemo eaqu',
                    total: 57.05,
                    status: {
                        name: 'draft'
                    }
                },
                {
                    id: 12,
                    invoice_number: '40871',
                    due_date: '18/10/1970',
                    terms: 'Inventore et iusto expli',
                    total: 143.51,
                    status: {
                        name: 'archived'
                    }
                },
                {
                    id: 14,
                    invoice_number: '93706',
                    due_date: '12/03/1989',
                    terms: 'A praesentium deleniti',
                    total: 25.92,
                    status: {
                        name: 'pending'
                    }
                },
                {
                    id: 18,
                    invoice_number: '65715',
                    due_date: '14/07/2020',
                    terms: 'Minima alias nihil',
                    total: 79.76,
                    status: {
                        name: 'draft'
                    }
                },
                {
                    id: 27,
                    invoice_number: '72820',
                    due_date: '15/10/1975',
                    terms: 'Non deserunt numquam natus',
                    total: 89.73,
                    status: {
                        name: 'pending'
                    }
                }
            ]
        }
    }
}
</script>
