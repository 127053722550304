/**
 * The customer repository.
 * @author Christophe Lablancherie <clablancherie@projeo.io>
 * @param {Object} client - The injected client.
 * @returns {Object} The public object.
 */
export default function (client) {
    return {
        /**
         * Store a customer.
         *
         * @param data
         *
         * @returns {Promise}
         */
        store (data) {
            return client.post('/customers', data).then(response => {
                return response.data
            })
        },

        /**
         * Get tenant customers
         *
         * @returns {Promise} Promise object represents the HTTP request.
         */
        getCustomers () {
            return client.get('/customers').then(
                response => response.data
            )
        },

        /**
         * Get tenant customers
         *
         * @param {number} id - The customer id.
         *
         * @returns {Promise} Promise object represents the HTTP request.
         */
        getCustomer (id) {
            return client.get('/customers/' + id).then(
                response => response.data.data
            )
        },

        /**
         * Search tenant customers with pagination
         *
         * @param {number} numPage - The number of the page.
         * @param {object} data
         *
         * @returns {Promise} Promise object represents the HTTP request.
         */
        searchCustomersWithPagination (numPage, data) {
            const isDeleted = (data.deletedRequest === true) ? 1 : 0
            return client.get('/customers?page=' + numPage + '&isDeleted=' + isDeleted).then(
                response => response.data
            )
        },

        /**
         * Get customer estimates by page.
         * @param {number} numPage - The number of the page.
         * @param {number} id
         * @returns {Promise} Promise object represents the HTTP request.
         */
        getCustomerEstimatesByPage (numPage, id) {
            return client.get('/customers/' + id + '/estimates?include=customer&page=' + numPage).then(
                response => response.data
            )
        },

        /**
         * Get customer invoices by page.
         * @param {number} numPage - The number of the page.
         * @param {number} id
         * @returns {Promise} Promise object represents the HTTP request.
         */
        getCustomerInvoicesByPage (numPage, id) {
            return client.get('/customers/' + id + '/invoices?include=customer&page=' + numPage).then(
                response => response.data
            )
        },

        /**
         * Delete customer.
         *
         * @param {number} id - The customer id.
         *
         * @returns {Promise} Promise object represents the HTTP request.
         */
        delete (id) {
            return client.delete('/customers/' + id)
        }
    }
}
