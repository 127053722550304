import Vue from 'vue'
import Vuex from 'vuex'
import Statistics from './modules/statistics'
import Customers from './modules/customers'
import WorkingHours from './modules/workingHours'
import Taxes from './modules/taxes'
import Modale from './modules/modale'
import Subscription from './modules/subscription'
import Benefits from './modules/benefits'
import AppointmentTypes from './modules/appointmentTypes'
import Availabilities from './modules/availabilities'
import User from './modules/user'

Vue.use(Vuex)

export default new Vuex.Store({
    strict: false,
    modules: {
        statistics: Statistics,
        customers: Customers,
        workingsHours: WorkingHours,
        taxes: Taxes,
        modale: Modale,
        subscription: Subscription,
        benefits: Benefits,
        appointmentTypes: AppointmentTypes,
        availabilities: Availabilities,
        user: User
    }
})
