<template>
    <transition name="fade">
        <div class="fixed z-10 inset-0 overflow-y-auto">
            <!-- pour centrer la modale -->
            <div class="flex items-center justify-center h-screen text-center sm:block sm:p-0">
                <div class="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div @click.self="closeModal" class="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>
                <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                <!-- Fenêtre de la modale  -->
                <div
                    class="xl:max-w-3xl md:max-w-xl max-w-full mx-0 sm:mx-4 inline-block align-middle bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all "
                    role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                    <div class="sm:flex sm:items-start">
                        <div class="my-3 mx-5 justify-center sm:ml-4">

                            <!-- HEADER -->
                            <h3 class="text-xl text-center leading-6 font-bold text-deeppurple-dark"
                                id="modal-headline"
                            >
                                {{ modalTitle }}
                            </h3>

                            <!-- BODY -->
                            <div class="mt-2">
                                <form class="space-y-8">
                                    <div class="flex flex-col space-y-4 text-sm">
                                        <div class="w-full">
                                            <label for="name"
                                                   class="block text-sm font-medium text-blacktext-light"
                                                   :class="{'text-error-regular': $v.name.$error && isSubmitted}">
                                                Pseudonyme
                                                <span class="text-error-regular"> *</span>
                                            </label>
                                            <div class="mt-1">
                                                <input
                                                    type="text"
                                                    name="name"
                                                    id="name"
                                                    class="w-full px-3 py-2 projeo-input"
                                                    :class="{'border border-error-regular': $v.name.$error && isSubmitted}"
                                                    required="required"
                                                    v-model.trim="name"
                                                    @input="setContactName($event.target.value)"
                                                />
                                            </div>
                                            <!-- Api validation -->
                                            <div class="text-error-regular mt-2 text-xs" v-if="errors.length > 0">
                                                <div v-for="(error, index) in errors" :key="index">
                                                    <div v-if="error.name">
                                                        <div v-for="(message, index) in error.name"
                                                             :key="index">
                                                            {{ message }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="w-full">
                                            <label
                                                for="first_name"
                                                class="block text-sm font-medium text-blacktext-light"
                                            >
                                                Prénom
                                            </label>
                                            <div class="mt-1">
                                                <input
                                                    type="text"
                                                    name="first_name"
                                                    id="first_name"
                                                    class="w-full px-3 py-2 projeo-input"
                                                    required="required"
                                                    v-model.trim="first_name"
                                                    @input="setFirstName($event.target.value)"
                                                />
                                            </div>
                                            <!-- Api validation -->
                                            <div class="text-error-regular mt-2 text-xs" v-if="errors.length > 0">
                                                <div v-for="(error, index) in errors" :key="index">
                                                    <div v-if="error.first_name">
                                                        <div v-for="(message, index) in error.first_name"
                                                             :key="index">
                                                            {{ message }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="w-full">
                                            <label
                                                for="last_name"
                                                class="block text-sm font-medium text-blacktext-light"
                                            >
                                                Nom
                                            </label>
                                            <div class="mt-1">
                                                <input
                                                    type="text"
                                                    name="last_name"
                                                    id="last_name"
                                                    class="w-full px-3 py-2 projeo-input"
                                                    required="required"
                                                    v-model.trim="last_name"
                                                    @input="setLastName($event.target.value)"
                                                />
                                            </div>
                                            <!-- Api validation -->
                                            <div class="text-error-regular mt-2 text-xs" v-if="errors.length > 0">
                                                <div v-for="(error, index) in errors" :key="index">
                                                    <div v-if="error.last_name">
                                                        <div v-for="(message, index) in error.last_name"
                                                             :key="index">
                                                            {{ message }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="w-full">
                                            <label
                                                for="stripe_connect_account_id"
                                                class="block text-sm font-medium text-blacktext-light"
                                            >
                                                Numéro de paiement
                                                <br>
                                                <small class="text-red-800">(A rappeler lors de vos échanges avec le support)</small>
                                            </label>
                                            <div class="mt-1">
                                                <input
                                                    type="text"
                                                    name="stripe_connect_account_id"
                                                    id="stripe_connect_account_id"
                                                    class="w-full px-3 py-2 projeo-input"
                                                    required="required"
                                                    v-model.trim="stripe_connect_account_id"
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                        <div class="w-full">
                                            <label for="email" class="block text-sm font-medium text-blacktext-light"
                                                   :class="{'text-error-regular': $v.email.$error && isSubmitted}">
                                                Courriel
                                                <span class="text-error-regular"> *</span>
                                            </label>
                                            <div class="mt-1">
                                                <input
                                                    id="email"
                                                    name="email"
                                                    type="email"
                                                    class="w-full px-3 py-2 projeo-input"
                                                    :class="{'border border-error-regular': $v.email.$error && isSubmitted}"
                                                    required="required"
                                                    v-model.trim="email"
                                                    @input="setEmail($event.target.value)"
                                                />
                                            </div>
                                            <div class="text-error-regular mt-2 text-xs"
                                                 v-if="$v.email.$error && isSubmitted">
                                                <span v-if="!$v.email.required">Le courriel est obligatoire.</span>
                                                <span v-if="!$v.email.email">Le courriel n'est pas au bon format.</span>
                                            </div>
                                            <div class="text-error-regular mt-2 text-xs" v-if="errors.length > 0">
                                                <div v-for="(error, index) in errors" :key="index">
                                                    <div v-if="error.email">
                                                        <div v-for="(message, index) in error.email" :key="index">
                                                            {{ message }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="w-full flex space-x-3">
                                            <div class="w-full">
                                                <label for="phone_number"
                                                       class="block text-sm font-medium text-blacktext-light">
                                                    Numéro de téléphone
                                                </label>
                                                <div class="mt-1">
                                                    <input
                                                        type="text"
                                                        name="phone_number"
                                                        id="phone_number"
                                                        class="w-full px-3 py-2 projeo-input"
                                                        v-model.trim="phone_number"
                                                        @input="setPhoneNumber($event.target.value)"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="flex justify-end items-center">
                                        <button type="button" class="projeo-btn-white px-4 py-1 mr-3 rounded-xl"
                                                @click="closeModal">
                                            Annuler
                                        </button>
                                        <button v-if="isEdition" type="button"
                                                class="projeo-btn-deeppurple px-4 py-1 rounded-xl"
                                                @click="updateCustomer">
                                            Modifier
                                        </button>
                                        <button v-else type="button" class="projeo-btn-deeppurple px-4 py-1 rounded-xl"
                                                @click="createCustomer">
                                            Enregistrer
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import api from '@/clients/api'
import {validationMixin} from 'vuelidate'
import {email, required, numeric} from 'vuelidate/lib/validators'

export default {
    name: 'AddCustomerModal',
    mixins: [validationMixin],
    computed: {},
    props: ['modalTitle', 'isEdition', 'customerId', 'routeName'],
    data () {
        return {
            errors: [],
            id: '',
            name: '',
            first_name: '',
            last_name: '',
            phone_number: '',
            email: '',
            role: '',
            stripe_connect_account_id: 'N/A',
            isSubmitted: false
        }
    },
    validations: {
        name: {
            required
        },
        email: {
            required,
            email
        },
        phone_number: {
            required,
            numeric
        },
    },
    mounted () {
        this.loadCustomer(this.customerId)
    },
    created () {
    },
    methods: {
        loadCustomer (id) {
            if (id) {
                api.get('/customers/' + id)
                    .then(result => {
                        this.id = result.data.data.id
                        this.name = result.data.data.name
                        this.first_name = result.data.data.first_name
                        this.last_name = result.data.data.last_name
                        this.email = result.data.data.email
                        this.phone_number = result.data.data.phone_number
                        this.role = result.data.data.role.name
                        this.stripe_connect_account_id = result.data.data.stripe_connect_account_id
                    })
                    .catch(error => {
                        throw new Error(`API ${error}`)
                    })
            }
        },
        closeModal () {
            this.refreshModal()
            this.$emit('close')
        },
        refreshModal () {
            this.id = ''
            this.name = ''
            this.email = ''
            this.phone_number = ''
            this.errors = []
            this.isSubmitted = false
        },
        setName (value) {
            this.name = value
            this.$v.name.$touch()
        },
        setFirstName (value) {
            this.first_name = value
            this.$v.first_name.$touch()
        },
        setLastName (value) {
            this.last_name = value
            this.$v.last_name.$touch()
        },
        setEmail (value) {
            this.email = value
            this.$v.email.$touch()
        },
        setPhoneNumber (value) {
            this.phone_number = value
            this.$v.phone_number.$touch()
        },
        createCustomer () {
            this.isSubmitted = true
            this.errors = []
            this.$v.$touch()

            if (!this.$v.$invalid) {
                api.post('/customers/store', {
                    contact_name: this.contact_name,
                    company_name: this.company_name,
                    company_number: this.company_number,
                    email: this.email,
                    street_number: this.street_number,
                    address: this.street_address,
                    zip_code: this.zip,
                    city: this.city,
                    phone_number: this.phone_number,
                    mobile_number: this.mobile_number,
                    country_id: this.country_id,
                    currency_id: this.currency_id,
                    language_id: this.language_id
                })
                    .then(result => {
                        this.$router.push({name: 'CustomersDetails', params: {id: result.data.data.id}}, () => {
                            this.$toasted.success('Le client a bien été ajouté.', {
                                position: 'top-center',
                                duration: 2000
                            })
                        })
                    })
                    .catch(error => {
                        this.errors.push(error.response.data.errors)
                        throw new Error(`API ${error}`)
                    })
            }
        },
        updateCustomer () {
            this.isSubmitted = true
            this.errors = []
            this.$v.$touch()

            if (!this.$v.$invalid) {
                api.put('/users/' + this.id + '/update', {
                    name: this.name,
                    first_name: this.first_name,
                    last_name: this.last_name,
                    email: this.email,
                    phone_number: this.phone_number,
                })
                    .then(result => {
                        this.$router.push({name: 'Customers', params: {id: result.data.data.id}}, () => {
                            this.$emit('reloadDetail', this.id)
                            this.$toasted.success('Le client a bien été modifié.', {
                                position: 'top-center',
                                duration: 2000
                            })
                        })
                    })
                    .catch(error => {
                        this.errors.push(error.response.data.errors)
                        throw new Error(`API ${error}`)
                    })
            }
        }
    }
}
</script>
