<template>
    <div class="px-10">
        <section class="content-header mt-5 text-grey-dark">
            <div class="flex flex-col md:flex-row justify-between">
                <div class="flex flex-row items-center mb-3">
                    <h1 class="text-xl xl:text-2xl font-sans font-semibold mb-1">Mes prestations</h1>
                </div>
            </div>

            <div class="space-y-3 mb-3">
                <div
                    class="flex sm:flex-row flex-col-reverse md:items-center items-start rounded-md sm:space-x-3 space-x-0 sm:text-base text-sm">

                    <!-- BOUTON AJOUTER saisie de temps qui ouvre une modal-->
                    <button type="button"
                            class="projeo-btn-deeppurple md:text-lg sm:mb-0 mb-3 text-base font-medium pl-5 pr-8 py-2 rounded-xl md:flex hidden"
                            @click="addBenefit">
                        + Ajouter une prestation
                    </button>
                </div>
            </div>
        </section>

        <section class="flex flex-col text-sm flex-1 justify-between">
            <div v-if="benefits.length">
                <table class="w-full table-fixed border-separate table-spacing">
                    <thead class="bg-grey-light text-deeppurple-dark">
                    <tr class="font-bold rounded-lg shadow-md">
                        <th scope="col" class="py-4">Nom de la prestation</th>
                        <th scope="col" class="py-4">Description</th>
                        <th scope="col" class="py-4">Prix</th>
                        <th scope="col" class="py-4">Actions</th>
                    </tr>
                    </thead>
                    <tbody class="bg-white">
                    <tr v-for="benefit in benefits" :key="benefit.id"
                        class="text-grey-dark text-center rounded-lg shadow-md p-2">
                        <td class="py-2">{{ benefit.name }}</td>
                        <td class="py-2">{{ (benefit.description) ? benefit.description : '-' }}</td>
                        <td class="py-2">
                          {{ benefit.price ? benefit.price + ' €' : '-' }}
                        </td>
                        <td class="py-2">
                            <button
                                @click="editBenefit(benefit)"
                                type="button"
                                class="inline-flex flex-col items-center mx-2 group"
                            >
                                <font-awesome-icon :icon="['fas', 'edit']" :style="{ color: '#FFA400' }"/>
                                <span class="text-2xs leading-3 opacity-0 group-hover:opacity-100 transition duration-150 ease">
                                    Modifier
                                </span>
                            </button>
                            <button @click.prevent.stop="askConfirm(benefit)" type="button"
                                    class="inline-flex flex-col items-center mx-2 group">
                                <font-awesome-icon :icon="['fas', 'trash']" :style="{ color: '#ba000d' }"/>
                                <span
                                    class="text-2xs leading-3 opacity-0 group-hover:opacity-100 transition duration-150 ease">Supprimer</span>
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div v-if="benefits.length === 0" class="flex">
                <p class="bg-grey-light text-deeppurple-dark font-medium rounded-lg shadow-md mx-auto px-8 py-3">Pas de
                    prestation disponible !</p>
            </div>
        </section>

        <transition name="fade">
            <base-modal
                v-if="showModal"
                @close-modal="closeModal"
                :is-edition="this.isEdition"
                @save="save(benefit)"
                @update="updateBenefit(benefit)"
                modal-title="Ajouter une prestation"
                description="Ajouter une prestation vous permettra de gagner du temps dans la création de vos devis et la gestion de votre comptabilité."
            >
                <form>
                    <div class="mb-5">
                        <label for="name" class="block text-sm font-medium text-gray-700">Prestation<span
                            class="text-red-500 ml-1">*</span></label>
                        <div class="mt-2">
                            <input v-model="benefit.name" type="text" name="name" id="name" maxlength="255"
                                   class="block w-full px-3 py-2 border focus:border-deeppurple-light focus:bg-purple-100 focus:outline-none shadow rounded-md">
                        </div>
                        <p v-if="errors && errors.name" class="text-red-400 italic text-xs">{{ errors.name[0] }}</p>
                    </div>
                    <div class="mb-5">
                        <label for="description" class="block text-sm font-medium text-gray-700">Description</label>
                        <div class="mt-2">
                            <textarea v-model="benefit.description" rows="8" name="description" id="description"
                                      maxlength="65535"
                                      class="block w-full px-3 py-2 text-base border focus:border-deeppurple-light focus:bg-purple-100 focus:outline-none shadow rounded-md"></textarea>
                        </div>
                        <p v-if="errors && errors.description" class="text-red-400 italic text-xs">
                            {{ errors.description[0] }}</p>
                    </div>
                    <div class="flex space-x-3">
                        <div class="w-full">
                            <label for="price" class="block text-sm font-medium text-gray-700">
                                Prix unitaire
                            </label>
                            <div class="mt-2">
                                <input
                                    v-model="benefit.price"
                                    type="number" name="price"
                                    id="price"
                                    class="block w-full px-3 py-2 border focus:border-deeppurple-light
                                    focus:bg-purple-100 focus:outline-none shadow rounded-md"
                                >
                            </div>
                            <p
                                v-if="errors && errors.price"
                                class="text-red-500 italic text-xs"
                            >
                              {{ errors.price[0] }}
                            </p>
                        </div>
                    </div>
                </form>
            </base-modal>

            <ConfirmModal
                v-show="showValidationModal"
                :object="benefit"
                @close-confirmation-modal="closeConfirmationModal"
                @validate-action="deleteBenefit"
                ref="confirmModal"
                question="Voulez-vous vraiment supprimer cette prestation ?"
            />
        </transition>
    </div>
</template>

<script>
import BaseModal from '@/components/BaseModal'
import ConfirmModal from '@/Shared/ConfirmModal'

export default {
    components: {
        BaseModal,
        ConfirmModal
    },
    computed: {
      benefits () {
        return this.$store.state.benefits.benefits
      },
      showModal () {
        return this.$store.state.modale.showModal
      },
      showValidationModal () {
        return this.$store.state.modale.showValidationModal
      },
    },
    mounted() {
      this.$store.dispatch('benefits/getBenefits')
    },
    data () {
        return {
            isEdition: false,
            currentPage: 1,
            lastPage: 1,
            totalPages: '',
            showForm: false,
            showConfirm: false,
            showValidation: false,
            edition: false,
            modalIsOpen: false,
            toastIsOpen: false,
            modalTitle: '',
            toastMessage: '',
            toastStyle: '',
            benefit: {
                id: '',
                name: '',
                description: '',
                price: ''
            },
            errors: {}
        }
    },
    methods: {
        resetFields () {
            this.benefit = {
                name: '',
                description: '',
                price: '',
                unit_id: 1
            }
            this.errors = {}
        },
        addBenefit () {
            this.$store.commit('modale/toggleModal')
            this.modalTitle = 'Ajouter une prestation'
            this.modalIsOpen = true
        },
        save (benefit) {
            const formData = new FormData()
            formData.append('name', benefit.name)
            formData.append('description', benefit.description)
            formData.append('price', benefit.price)

            this.$store.dispatch('benefits/create', {vm: this, formData})
            this.$store.commit('modale/toggleModal')
            this.resetFields()
        },
        editBenefit (benefit) {
            this.isEdition = true
            this.modalTitle = 'Modifier une prestation'
            this.$store.commit('modale/toggleModal')
            this.benefit = benefit
        },
        updateBenefit (benefit) {
            const data = {
                'id': benefit.id,
                'name': benefit.name,
                'description': benefit.description,
                'price': benefit.price
            }

            this.$store.dispatch('benefits/update', {vm: this, data})
            this.$store.commit('modale/toggleModal')
            this.resetFields()
        },
        askConfirm (benefit) {
            this.benefit = benefit
            this.$store.commit('modale/toggleValidationModal')
        },
        askDelete (id) {
            this.benefit.id = id
            this.showConfirm = true
            this.modalIsOpen = true
        },
        closeModal () {
            this.$store.commit('modale/toggleModal')
        },
        closeConfirmationModal () {
            this.$store.commit('modale/toggleValidationModal')
        },
        deleteBenefit (benefit) {
            const data = {
                'id': benefit.id
            }
            this.$store.dispatch('benefits/delete', {vm: this, data})
            this.$store.commit('modale/toggleValidationModal')
        }
    }
}
</script>
