<template>
    <section class="flex flex-col text-sm flex-1">
        <div class="flex flex-col w-full m-auto p-auto">
            <div class="flex flex-col pb-8">
                <div class="flex flex-row relative">
                    <!-- title -->
                    <h2 class="font-sans text-grey-dark font-bold text-xl">{{ fileType.title }}</h2>
                    <!-- button -->
                    <button @click="$emit('redirectTo')" class="absolute right-1 self-center">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-5 h-5">
                            <path d="M22.707 12.707a1 1 0 000-1.414l-6-6a1 1 0 00-1.414 1.414L19.586 11H2a1 1 0 000 2h17.586l-4.293 4.293a1 1 0 001.414 1.414z"/>
                        </svg>
                    </button>
                </div>
                <div class="flex flex-col lg:flex-row mt-4">
                    <!-- for each: card -->
                    <card v-for="file in files"
                        :key="file.id"
                        v-show="file.id"
                        :fileType="fileType"
                        :file="file"
                        @askEdit="edit"
                        @askDelete="askDelete" />
                    <!-- Add button card -->
                    <button-card :fileType="fileType" @addFile="addFile" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import ButtonCard from './ButtonCard.vue'
import Card from './Card.vue'

export default {
    name: 'CardsSection',
    components: {
        Card,
        ButtonCard
    },
    props: ['fileType', 'files', 'isDeleted'],
    methods: {
        addFile () {
            this.$emit('addFile')
        },
        edit (id) {
            this.$emit('edit', id)
        },
        askDelete (id) {
            this.$emit('askDelete', id)
        }
    }
}
</script>
