<template>
    <div class="px-10">
        <section class="content-header mt-5 text-grey-dark">
            <div class="flex flex-col md:flex-row justify-between">
                <div class="flex flex-row items-center mb-3">
                    <h1 class="text-xl xl:text-2xl font-sans font-semibold mb-1">Mes types de rendez-vous</h1>
                </div>
            </div>

            <div class="space-y-3 mb-3">
                <div
                    class="flex sm:flex-row flex-col-reverse md:items-center items-start rounded-md sm:space-x-3 space-x-0 sm:text-base text-sm">

                    <!-- BOUTON AJOUTER saisie de temps qui ouvre une modal-->
                    <button type="button"
                            class="projeo-btn-deeppurple md:text-lg sm:mb-0 mb-3 text-base font-medium pl-5 pr-8 py-2 rounded-xl md:flex hidden"
                            @click="addBenefit">
                        + Ajouter un type de rendez-vous
                    </button>
                </div>
            </div>
        </section>

        <section class="flex flex-col text-sm flex-1 justify-between">
            <div v-if="appointmentTypes.length">
                <table class="w-full table-fixed border-separate table-spacing">
                    <thead class="bg-grey-light text-deeppurple-dark">
                    <tr class="font-bold rounded-lg shadow-md">
                        <th scope="col" class="py-4">Nom du type de rendez-vous</th>
                        <th scope="col" class="py-4">Durée (en minutes)</th>
                        <th scope="col" class="py-4">Prix</th>
                        <th scope="col" class="py-4">Acompte obligatoire ?</th>
                        <th scope="col" class="py-4">Pourcentage de l'acompte</th>
                        <th scope="col" class="py-4">Actions</th>
                    </tr>
                    </thead>
                    <tbody class="bg-white">
                    <tr v-for="appointmentType in appointmentTypes" :key="appointmentType.id"
                        class="text-grey-dark text-center rounded-lg shadow-md p-2">
                        <td class="py-2">{{ appointmentType.name }}</td>
                        <td class="py-2">{{ appointmentType.duration_in_minutes }}</td>
                        <td class="py-2">
                            {{ appointmentType.price ? appointmentType.price + ' €' : '-' }}
                        </td>
                        <td class="py-2">
                            {{ appointmentType.is_depositable ? 'Oui' : 'Non' }}
                        </td>
                        <td class="py-2">
                            {{ appointmentType.is_depositable ? appointmentType.deposit_percentage + ' %' : '-' }}
                        </td>
                        <td class="py-2">
                            <button
                                @click="editAppointmentType(appointmentType)"
                                type="button"
                                class="inline-flex flex-col items-center mx-2 group"
                            >
                                <font-awesome-icon :icon="['fas', 'edit']" :style="{ color: '#FFA400' }"/>
                                <span class="text-2xs leading-3 opacity-0 group-hover:opacity-100 transition duration-150 ease">
                                    Modifier
                                </span>
                            </button>
                            <button @click.prevent.stop="askConfirm(appointmentType)" type="button"
                                    class="inline-flex flex-col items-center mx-2 group">
                                <font-awesome-icon :icon="['fas', 'trash']" :style="{ color: '#ba000d' }"/>
                                <span
                                    class="text-2xs leading-3 opacity-0 group-hover:opacity-100 transition duration-150 ease">Supprimer</span>
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div v-if="appointmentTypes.length === 0" class="flex">
                <p class="bg-grey-light text-deeppurple-dark font-medium rounded-lg shadow-md mx-auto px-8 py-3">
                  Vous n'avez renseigné aucun type de rendez-vous.
                </p>
            </div>
        </section>

        <transition name="fade">
            <base-modal
                v-if="showModal"
                @close-modal="closeModal"
                :is-edition="this.isEdition"
                @save="save(appointmentType)"
                @update="updateAppointmentType(appointmentType)"
                modal-title="Ajouter un type de rendez-vous"
                description="Le type de rendez-vous permet d'indiquer la durée d'un rendez-vous pour vous clients."
            >
                <form>
                    <div class="mb-5">
                        <label for="name" class="block text-sm font-medium text-gray-700">Prestation<span
                            class="text-red-500 ml-1">*</span></label>
                        <div class="mt-2">
                            <input v-model="appointmentType.name" type="text" name="name" id="name" maxlength="255"
                                   class="block w-full px-3 py-2 border focus:border-deeppurple-light focus:bg-purple-100 focus:outline-none shadow rounded-md">
                        </div>
                        <p v-if="errors && errors.name" class="text-red-400 italic text-xs">{{ errors.name[0] }}</p>
                    </div>
                    <div class="mb-5">
                        <label for="duration_in_minutes" class="block text-sm font-medium text-gray-700">
                            Durée en minutes
                        </label>
                        <div class="mt-2">
                          <input v-model="appointmentType.duration_in_minutes" type="text" name="duration_in_minutes" id="duration_in_minutes" maxlength="255"
                                 class="block w-full px-3 py-2 border focus:border-deeppurple-light focus:bg-purple-100 focus:outline-none shadow rounded-md">
                        </div>
                        <p v-if="errors && errors.duration_in_minutes" class="text-red-400 italic text-xs">
                            {{ errors.duration_in_minutes[0] }}</p>
                    </div>
                    <div class="mb-5">
                        <label for="price" class="block text-sm font-medium text-gray-700">Tarif</label>
                        <div class="mt-2">
                          <input v-model="appointmentType.price" type="text" name="price" id="price" maxlength="255"
                                 class="block w-full px-3 py-2 border focus:border-deeppurple-light focus:bg-purple-100 focus:outline-none shadow rounded-md">
                        </div>
                        <p v-if="errors && errors.price" class="text-red-400 italic text-xs">
                            {{ errors.price[0] }}</p>
                    </div>
                    <div class="flex space-x-3">
                        <div class="w-1/2">
                            <div class="mt-2">
                                <div class="relative flex items-start">
                                    <div class="flex items-center h-5">
                                        <input
                                            id="comments"
                                            v-model="appointmentType.is_depositable"
                                            name="comments"
                                            type="checkbox"
                                            @click="toggleDeposit(appointmentType.is_depositable)"
                                            class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300
                                            rounded"
                                        >
                                    </div>
                                    <div class="ml-3 text-sm">
                                        <label for="comments" class="font-medium text-gray-700">Demander un acompte ?</label>
                                    </div>
                                </div>
                            </div>
                            <p
                                v-if="errors && errors.is_depositable"
                                class="text-red-500 italic text-xs"
                            >
                              {{ errors.is_depositable[0] }}
                            </p>
                        </div>
                        <div v-show="appointmentType.is_depositable == 1" class="w-1/2">
                            <label for="deposit_percentage" class="block text-sm font-medium text-gray-700">
                                Pourcentage de l'acompte
                            </label>
                            <div class="mt-2">
                                <input
                                    v-model="appointmentType.deposit_percentage"
                                    type="number" name="deposit_percentage"
                                    id="deposit_percentage"
                                    class="block w-full px-3 py-2 border focus:border-deeppurple-light
                                    focus:bg-purple-100 focus:outline-none shadow rounded-md"
                                >
                            </div>
                            <p
                                v-if="errors && errors.deposit_percentage"
                                class="text-red-500 italic text-xs"
                            >
                              {{ errors.deposit_percentage[0] }}
                            </p>
                        </div>
                    </div>
                </form>
            </base-modal>

            <ConfirmModal
                v-show="showValidationModal"
                :object="appointmentType"
                @close-confirmation-modal="closeConfirmationModal"
                @validate-action="deleteAppointmentType"
                ref="confirmModal"
                question="Voulez-vous vraiment supprimer ce type de rendez-vous ?"
            />
        </transition>
    </div>
</template>

<script>
import BaseModal from '@/components/BaseModal'
import ConfirmModal from '@/Shared/ConfirmModal'

export default {
    components: {
        BaseModal,
        ConfirmModal
    },
    computed: {
        appointmentTypes () {
            return this.$store.state.appointmentTypes.appointmentTypes
        },
        showModal () {
            return this.$store.state.modale.showModal
        },
        showValidationModal () {
            return this.$store.state.modale.showValidationModal
        },
        units () {
            return this.$store.state.units.units
        }
    },
    data () {
        return {
            isEdition: false,
            currentPage: 1,
            lastPage: 1,
            totalPages: '',
            showForm: false,
            showConfirm: false,
            showValidation: false,
            edition: false,
            modalIsOpen: false,
            toastIsOpen: false,
            modalTitle: '',
            toastMessage: '',
            toastStyle: '',
            appointmentType: {
                id: '',
                name: '',
                duration_in_minutes: '',
                price: '',
                is_depositable: false,
                deposit_percentage: 0
            },
            errors: {}
        }
    },
    mounted () {
        this.$store.dispatch('appointmentTypes/getAppointmentTypes')
    },
    methods: {
        closeModal () {
            this.$store.commit('modale/toggleModal')
            this.resetFields()
        },
        resetFields () {
            this.appointmentType = {
                name: '',
                duration_in_minutes: '',
                deposit_percentage: 0,
                is_depositable: 1,
                price: '',
                unit_id: 1
            }
            this.errors = {}
        },
        toggleDeposit (value) {
            this.is_depositable = !value
        },
        addBenefit () {
            this.$store.commit('modale/toggleModal')
            this.modalTitle = 'Ajouter une prestation'
            this.modalIsOpen = true
        },
        save (appointmentType) {
            let formData = {
                'name': appointmentType.name,
                'price': appointmentType.price,
                'duration_in_minutes': appointmentType.duration_in_minutes,
                'is_depositable': appointmentType.is_depositable,
                'deposit_percentage': appointmentType.deposit_percentage,
            }

            this.$store.dispatch('appointmentTypes/create', {vm: this, formData})
            this.$store.commit('modale/toggleModal')
            this.resetFields()
        },
        askConfirm (appointmentType) {
            this.appointmentType = appointmentType
            this.$store.commit('modale/toggleValidationModal')
        },
        editAppointmentType (appointmentType) {
            this.isEdition = true
            this.modalTitle = 'Modifier une prestation'
            this.$store.commit('modale/toggleModal')
            this.appointmentType = appointmentType
        },
        updateAppointmentType (appointmentType) {
            const data = {
                'id': appointmentType.id,
                'name': appointmentType.name,
                'price': appointmentType.price,
                'duration_in_minutes': appointmentType.duration_in_minutes,
                'is_depositable': appointmentType.is_depositable,
                'deposit_percentage': (appointmentType.is_depositable == 1) ? appointmentType.deposit_percentage : 0,
            }

            this.$store.dispatch('appointmentTypes/update', {vm: this, data})
            this.$store.commit('modale/toggleModal')
            this.resetFields()
        },
        askDelete (id) {
            this.appointmentType.id = id
            this.showConfirm = true
            this.modalIsOpen = true
        },
        closeConfirmationModal () {
            this.$store.commit('modale/toggleValidationModal')
        },
        deleteAppointmentType (appointmentType) {
            const data = {
                'id': appointmentType.id
            }
            this.$store.dispatch('appointmentTypes/delete', {vm: this, data})
            this.$store.commit('modale/toggleValidationModal')
            this.resetFields()
        }
    }
}
</script>
