import { userRepository } from '@/container'

export default {
    namespaced: true,
    state: {
        isTrialing: false,
        trialDaysRemain: 0
    },
    getters: {},
    actions: {
        getUserProfile ({ commit }) {
            userRepository().getUserProfile().then(response => {
                commit('CHECK_SUBSCRIPTION_TRIAL_STATUS', response.data)
            })
        },
    },
    mutations: {
        CHECK_SUBSCRIPTION_TRIAL_STATUS (state, user) {
            if(user.tenant.subscription.stripe_status === 'trialing') {
                state.isTrialing = true
                state.trialDaysRemain = user.tenant.subscription.trial_ends_at
            }
        },
    }
}
