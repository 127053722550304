<template>
    <div class="text-center section">
        <h2 class="h2agenda">Agenda de la semaine</h2>

        <FullCalendar :options="calendarOptions" v-bind:style="style" />

    </div>
</template>
<script>
import api from '@/clients/api'
import '@fullcalendar/core/vdom'
import FullCalendar from '@fullcalendar/vue'
import timeGridPlugin from '@fullcalendar/timegrid'

export default {
    components: {
        FullCalendar // make the <FullCalendar> tag available
    },
    data () {
        return {
            calendarOptions: {
                plugins: [timeGridPlugin],
                initialView: 'timeGridWeek',
                weekends: true,
                hiddenDays: [0],
                firstDay: 1,
                locale: 'fr',
                buttonText: {
                    today: "Aujourd'hui"
                },
                allDaySlot: true,
                allDayText: 'Journée entière',
                nowIndicator: true,
                slotMinTime: '07:00:00',
                slotMaxTime: '22:00:00',
                events: [],
                eventColor: '#7425B4'
            }
        }
    },
    mounted () {
        api.get('appointments')
            .then(response => {
                this.calendarOptions.events = response.data.data
            })
            .catch(function (error) {
                console.error(error.response)
            })
    }
}
</script>
<style>
    .text-center{
        padding-left: 5em;
        padding-right: 5em;
        padding-top: 1em;
        padding-bottom: 1em;
    }
    .h2agenda{
        font-size: 1.2em;
        font-weight: bold;
    }
</style>
