<template>
    <section class="flex flex-col text-sm flex-1 justify-between" v-if="isLoaded">
        <div v-if="workinghours.length > 0">
            <table class="min-w-full table-fixed border-separate table-spacing">
                <thead class="bg-grey-light text-deeppurple-dark overflow-hidden">
                <tr class="font-bold rounded-lg shadow-md">
                    <th scope="col" class="py-4 pl-8 xl:w-1/7 w-auto text-left">Date</th>
                    <th scope="col" class="py-4 text-center w-1/7">Client</th>
                    <th scope="col" class="py-4 text-center w-1/7">Prestation</th>
                    <th scope="col" class="py-4 text-center w-1/7">Temps</th>
                    <th scope="col" class="py-4 text-center w-1/7">Taux Horaire</th>
                    <th scope="col" class="py-4 text-center w-1/7">Montant</th>
                </tr>
                </thead>
                <tbody class="bg-white">
                <tr v-for="workinghour in workinghours" :key="workinghour.id" class="text-grey-dark rounded-lg shadow-md">
                    <td class="py-4 pl-8 xl:w-1/7 w-auto text-left">{{ workinghour.entry_date }}</td>
                    <td class="py-3 text-center w-1/7">{{ workinghour.customer.name }}</td>
                    <td class="py-3 text-center w-1/7" v-if="workinghour.benefit">{{ workinghour.benefit.name }}</td>
                    <td class="py-3 text-center w-1/7" v-else>{{ "-" }}</td>
                    <td class="py-3 text-center w-1/7">{{ workinghour.nb_hours + "h" + workinghour.nb_minutes }}</td>
                    <td class="py-3 text-center w-1/7">{{ workinghour.hourly_rate }}</td>
                    <td class="py-3 text-center w-1/7">{{ workinghour.total }}</td>
                </tr>
                </tbody>
            </table>
        </div>
        <div v-else>
            <ul class="mb-3">
                <li class="bg-white rounded-xl shadow-md overflow-hidden px-4 py-4 flex justify-around items-center space-x-4 sm:px6 sm:rounded-md">
                    <div class="text-left font-body font-bold text-gray-900">
                        Vous n'avez pas de saisie de temps.
                    </div>
                </li>
            </ul>
        </div>
    </section>
</template>

<script>

export default {
    components: {},
    data () {
        return {
            isLoaded: false
        }
    },
    computed: {
        workinghours () {
            return this.$store.state.workingsHours.workingHours
        }
    },
    mounted () { this.loadData() },
    methods: {
        async loadData () {
            await this.$store.dispatch('getWorkingHours', 4)
            this.isLoaded = true
        }
    }
}
</script>
