<template>
    <div>
        <div class="w-full flex justify-between items-center mb-6">
            <div class="tracking-wide"></div>
            <div class="block relative">
                <select v-model="year" @change="reloadStats(year)" class="block pl-2 pr-6 py-1 border-gray-300 appearance-none border focus:outline-none focus:ring-indigo-500 focus:border-deeppurple-dark text-sm rounded">
                    <option v-for="year in years" :value="year" :key="year">{{ year }}</option>
                </select>
                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-grey-dark">
                    <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                </div>
            </div>

        </div>
        <div class="w-11/12 mx-auto">
            <BarChart v-if="loaded" :chartData="chartData" :chartOptions="chartOptions" ></BarChart>
        </div>
    </div>
</template>

<script>
import api from '@/clients/api'
import BarChart from '@/components/Customers/Charts/BarChart'

export default {
    name: 'CustomerChart',
    components: { BarChart },
    props: {
        errors: Object
    },
    data () {
        return {
            loaded: false,
            year: new Date().getFullYear(),
            years: [new Date().getFullYear() - 3, new Date().getFullYear() - 2, new Date().getFullYear() - 1, new Date().getFullYear()],
            dataCollection: [],
            chartData: {
                labels: ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D'],
                datasets: [{
                    label: '€',
                    barThickness: 16,
                    borderWidth: 0,
                    minBarLength: 0,
                    backgroundColor: [
                        '#490A7D',
                        '#490A7D',
                        '#490A7D',
                        '#490A7D',
                        '#490A7D',
                        '#490A7D',
                        '#490A7D',
                        '#490A7D',
                        '#490A7D',
                        '#490A7D',
                        '#490A7D',
                        '#490A7D'
                    ],
                    hoverBackgroundColor: [
                        '#b085f5',
                        '#b085f5',
                        '#b085f5',
                        '#b085f5',
                        '#b085f5',
                        '#b085f5',
                        '#b085f5',
                        '#b085f5',
                        '#b085f5',
                        '#b085f5',
                        '#b085f5',
                        '#b085f5'
                    ],
                    data: []
                }]
            },
            chartOptions: {
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            fontColor: '#000'
                        },
                        gridLines: {
                            display: true,
                            borderDash: [4, 8],
                            offsetGridLines: false,
                            drawBorder: false,
                            zeroLineBorderDash: [4, 8]
                        }
                    }],
                    xAxes: [{
                        ticks: {
                            padding: 8,
                            fontColor: '#000'
                        },
                        gridLines: {
                            display: true,
                            color: '#b085f533',
                            drawBorder: false,
                            offsetGridLines: false,
                            lineWidth: 10,
                            tickMarkLength: 0
                        }
                    }]
                },
                legend: {
                    display: false
                },
                responsive: true,
                maintainAspectRatio: true
            }
        }
    },
    computed: {
    },
    mounted () {
        this.loadStats(this.year)
    },
    methods: {
        async loadStats (year) {
            api.post('/tenants/statistics/sales/months', { year: year })
                .then(result => {
                    this.dataCollection[1] = result.data[1]
                    this.dataCollection[2] = result.data[2]
                    this.dataCollection[3] = result.data[3]
                    this.dataCollection[4] = result.data[4]
                    this.dataCollection[5] = result.data[5]
                    this.dataCollection[6] = result.data[6]
                    this.dataCollection[7] = result.data[7]
                    this.dataCollection[8] = result.data[8]
                    this.dataCollection[9] = result.data[9]
                    this.dataCollection[10] = result.data[10]
                    this.dataCollection[11] = result.data[11]
                    this.dataCollection[12] = result.data[12]
                    this.loaded = true
                })
                .catch(error => {
                    throw new Error(`API ${error}`)
                })
        },
        async reloadStats (year) {
            this.loadStats(year)
        }
    },
    watch: {
        chartData () {
            this.chartData.datasets.data = this.dataCollection
        }
    }
}
</script>
