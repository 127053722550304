import {appointmentTypeRepository} from '@/container'

export default {
    namespaced: true,
    state: {
        appointmentTypes: []
    },
    getters: {},
    actions: {
        getAppointmentTypes ({ commit }) {
            appointmentTypeRepository().get().then(response => {
                commit('SET_APPOINTMENT_TYPES', response)
            })
        },
        create ({ commit }, {vm, formData}) {
            appointmentTypeRepository().store(formData).then(response => {
                commit('ADD_APPOINTMENT_TYPE', response.appointmentType)
                vm.$toasted.success(response.message, {
                    position: 'top-center',
                    duration: 2000
                })
            }).catch(error => {
                throw new Error(`API ${error}`)
            })
        },
        update ({ commit }, {vm, data}) {
            appointmentTypeRepository().update(data).then(response => {
                commit('UPDATE_APPOINTMENT_TYPE', response.appointmentType, data.id)
                vm.$toasted.success(response.message, {
                    position: 'top-center',
                    duration: 2000
                })
            }).catch(error => {
                throw new Error(`API ${error}`)
            })
        },
        duplicate ({ commit }, {vm, data}) {
            appointmentTypeRepository().duplicate(data).then(response => {
                commit('ADD_APPOINTMENT_TYPE', response.appointmentType, data.id)
                vm.$toasted.success(response.message, {
                    position: 'top-center',
                    duration: 2000
                })
            }).catch(error => {
                throw new Error(`API ${error}`)
            })
        },
        delete ({ commit }, {vm, data}) {
            appointmentTypeRepository().delete(data.id).then(response => {
                commit('DELETE_APPOINTMENT_TYPE', data.id)
                vm.$toasted.success(response.message, {
                    position: 'top-center',
                    duration: 2000
                })
            }).catch(error => {
                throw new Error(`API ${error}`)
            })
        },
    },
    mutations: {
        ADD_APPOINTMENT_TYPE (state, appointmentType) {
            state.appointmentTypes.push(appointmentType)
        },
        UPDATE_APPOINTMENT_TYPE (state, appointmentType, id) {
            const index = state.appointmentTypes.findIndex(appointmentType => appointmentType.id === id)
            state.appointmentTypes[index] = appointmentType
        },
        DELETE_APPOINTMENT_TYPE (state, id) {
            const index = state.appointmentTypes.findIndex(appointmentType => appointmentType.id === id)
            state.appointmentTypes.splice(index, 1)
        },
        SET_APPOINTMENT_TYPES (state, data) {
            state.appointmentTypes = data.data
        }
    }
}
