<template>
    <section class="flex flex-col text-sm flex-1 justify-between">
        <div class="flex flex-col w-full m-auto p-auto">
            <div class="flex flex-col pb-8">
                <div class="flex flex-row relative">
                    <!-- title -->
                    <h2 class="font-sans text-grey-dark font-bold text-xl">{{ fileType.title }}</h2>
                    <!-- button add -->
                    <button @click="$emit('addFile')" class="ml-7 mt-1">
                        <span class="px-4 font-sans text-xs text-white rounded-full bg-amber-regular">Saisir</span>
                    </button>
                    <!-- button redirect -->
                    <button @click="$emit('redirectTo')" class="absolute right-1 self-center">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-5 h-5">
                            <path d="M22.707 12.707a1 1 0 000-1.414l-6-6a1 1 0 00-1.414 1.414L19.586 11H2a1 1 0 000 2h17.586l-4.293 4.293a1 1 0 001.414 1.414z"/>
                        </svg>
                    </button>
                </div>
                <!-- table -->
                <div class="flex flex-nowrap mt-4">
                    <Table></Table>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Table from './Table.vue'
export default {
    name: 'CustomerBenefits',
    components: { Table },
    props: ['fileType'],
    methods: {
    }
}
</script>
