/**
 * The working hour repository.
 * @author Christophe Lablancherie <clablancherie@projeo.io>
 * @param {Object} client - The injected client.
 * @returns {Object} The public object.
 */
export default function (client) {
    return {
        /**
         * Get all working hours.
         * @returns {Promise} Promise object represents the HTTP request.
         */
        get (count = null) {
            let urlRequest = '/time-tracking?include=customer,benefit,tenant'
            if (count !== null) {
                urlRequest += '&count=' + count
            }
            return client.get(urlRequest).then(
                response => {
                    const workingHours = response.data.data.filter(item => {
                        return item.billed === false
                    })

                    const total = Math.round(workingHours.reduce((acc, cur) => {
                        return acc + cur.total
                    }, 0) * 100) / 100

                    return {
                        workingHours: workingHours,
                        total: total
                    }
                }
            )
        },

        /**
         * Get all estimates by page.
         * @param {number} numPage - The number of the page.
         * @returns {Promise} Promise object represents the HTTP request.
         */
        getWorkingHoursByPage (numPage) {
            return client.get('/time-tracking?include=customer,benefit,tenant&page=' + numPage).then(
                response => response.data
            )
        },

        /**
         * Get all estimates by page.
         * @param {number} numPage - The number of the page.
         * @param searchRequest
         * @param deletedRequest
         * @returns {Promise} Promise object represents the HTTP request.
         */
        getWorkingHoursBySearch (numPage, searchRequest, deletedRequest) {
            return client.post('/time-tracking/search?include=customer,benefit,tenant&page=' + numPage, {
                searchRequest: searchRequest,
                deletedRequest: deletedRequest
            })
                .then(
                    response => response.data
                )
        },

        /**
         * Get all working hours.
         * @returns {Promise} Promise object represents the HTTP request.
         */
        getWorkingHour (id) {
            return client.get('/time-tracking/' + id).then(
                response => {
                    return response.data.data
                }
            )
        },

        /**
         * Store a working hour.
         *
         * @param data
         *
         * @returns {Promise}
         */
        store (data) {
            return client.post('/time-tracking/store', data).then(response => {
                return response.data
            })
        },

        /**
         * Update a working hour.
         *
         * @param data
         *
         * @returns {Promise}
         */
        update (data) {
            return client.put('/time-tracking/' + data.id + '/update', data)
                .then(response => {
                    return response.data
                })
        },

        /**
         * Get all working hours.
         * @returns {Promise} Promise object represents the HTTP request.
         */
        delete (id) {
            return client.delete('/time-tracking/' + id).then(
                response => {
                    return response
                }
            )
        }
    }
}
