import {workingHourRepository} from '@/container'

export default {
    namespaced: true,
    state: {
        workingHours: [],
        workingHour: {},
        pagination: {
            currentPage: {},
            lastPage: {}
        }
    },
    getters: {},
    actions: {
        getWorkingHour ({ commit }, id) {
            workingHourRepository().getWorkingHour(id).then(response => {
                commit('SET_WORKING_HOUR', response)
            })
        },
        create ({ commit }, {vm, formData}) {
            workingHourRepository().store(formData).then(response => {
                commit('ADD_WORKING_HOUR', response.data)
                vm.$toasted.success(response.message, {
                    position: 'top-center',
                    duration: 2000
                })
            })
        },
        update ({ commit }, {vm, data}) {
            workingHourRepository().update(data).then(response => {
                commit('UPDATE_WORKING_HOUR', response.workingHour, data.id)
                vm.$toasted.success(response.message, {
                    position: 'top-center',
                    duration: 2000
                })
            }).catch(error => {
                throw new Error(`API ${error}`)
            })
        },
        delete ({ commit }, { vm, data }) {
            workingHourRepository().delete(data.id).then(response => {
                commit('DELETE_WORKING_HOUR', data.id)
                vm.$toasted.success(response.data, {
                    position: 'top-center',
                    duration: 2000
                })
            })
        },
        getWorkingHoursWithPagination ({ commit }, data) {
            workingHourRepository().getWorkingHoursByPage(data.numPage).then(response => {
                commit('SET_WORKING_HOURS_WITH_PAGINATION', {
                    workingHours: response.data,
                    currentPage: response.meta.pagination.current_page,
                    lastPage: response.meta.pagination.total_pages
                })
            })
        },
        getWorkingHoursBySearch ({ commit }, data) {
            workingHourRepository().getWorkingHoursBySearch(data.count, data.numPage, data.searchRequest, data.deletedRequest).then(response => {
                commit('SET_WORKING_HOURS_WITH_PAGINATION', {
                    workingHours: response.data,
                    currentPage: response.data.meta.pagination.current_page,
                    lastPage: response.data.meta.pagination.total_pages
                })
            })
        }
    },
    mutations: {
        ADD_WORKING_HOUR (state, data) {
            state.workingHours.push(data)
        },
        UPDATE_WORKING_HOUR (state, workingHour, id) {
            const index = state.workingHours.findIndex(workingHour => workingHour.id === id)
            state.workingHours[index] = workingHour
        },
        SET_WORKING_HOURS_WITH_PAGINATION (state, data) {
            state.workingHours = data.workingHours
            state.pagination.currentPage = data.currentPage
            state.pagination.lastPage = data.lastPage
        },
        SET_WORKING_HOUR (state, workingHour) {
            state.workingHour = workingHour
        },
        DELETE_WORKING_HOUR(state, id) {
            let index = state.workingHours.findIndex(workingHour => workingHour.id == id)
            state.workingHours.splice(index, 1)
        }
    }
}
