<template>
    <div v-if="loaded === true" class="m-20">
        <div class="text-2xl font-bold">
            Notifications
        </div>
        <div class="mt-10 text-gray-400 underline">
            <button @click="setFilterNotification">
                <span v-if="filter_request === 'all'" class="underline">
                    Filtrer par notifications non lues
                </span>
                <span v-if="filter_request !== 'all'" class="underline">
                    Afficher toutes les notifications
                </span>
            </button>
        </div>
        <table class="w-full mt-5">
            <thead class="text-purple-800 text-xl">
            <tr class="shadow-md rounded-lg">
                <td class="px-6 py-4 text-left font-bold pr-40 bg-gray-100 rounded-l-lg">
                    Titre
                </td>
                <td class="text-left font-bold w-3/5 bg-gray-100">
                    Description
                </td>
                <td class="text-left font-bold pr-20 bg-gray-100">
                    Date
                </td>
                <td class="text-center w-1/12 bg-gray-100">
                    <button @click="deleteAllNotifications" class="font-bold underline">Tout supprimer</button>
                </td>
                <td class="text-center font-bold w-1/6 bg-gray-100 rounded-r-lg">
                    <button @click="setAllNotificationsReadAt" class="font-bold underline">Tout marquer comme lu</button>
                </td>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="(notification, index) in notifications.data"
                :key="index"
                class="shadow-md rounded-lg"
                v-bind:class="{notificationRead: notification.read_at !== null}">
                <td class="px-6 py-4 w-1/6 text-left">
                    <div class="flex">
                        <img src="/img/layout/avatar@2x.png" class="h-16">
                        <span class="font-bold text-lg m-5">{{ notification.data.notification_title }}
                        </span>
                    </div>
                </td>
                <td class="text-left">
                    {{ notification.data.message }}
                </td>
                <td class="text-left">
                    {{ notification.diff_created_at }}
                </td>
                <td>
                    <svg
                        @click.prevent.stop="deleteNotification(notification)"
                        class="cursor-pointer h-10 center "
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        viewBox="0 0 100 100"
                        xml:space="preserve"
                    >
                        <path
                            id="Trash"
                            fill="#00000082"
                            d="M81 23.5H61V17c0-1.1-.9-2-2-2H41c-1.1 0-2 .9-2 2v6.5H19c-1.1 0-2 .9-2 2s.9 2 2 2h6.6V83c0 1.1.9 2 2 2h44.8c1.1 0 2-.9 2-2V27.5H81c1.1 0 2-.9 2-2s-.9-2-2-2zM43 19h14v4H43v-4zm27.4 62H29.6V27.5h40.8V81zM61 38.3v32c0 1.1-.9 2-2 2s-2-.9-2-2v-32c0-1.1.9-2 2-2s2 .8 2 2zm-18 0v32c0 1.1-.9 2-2 2s-2-.9-2-2v-32c0-1.1.9-2 2-2s2 .8 2 2z"
                            stroke="#00000082"
                            stroke-linecap="round"
                            stroke-width="2"
                        />
                        <g id="Info">
                            <path
                                fill="#00000082"
                                d="M1364-930V754H-420V-930h1784m8-8H-428V762h1800V-938z"
                                id="BORDER"
                                stroke="#00000082"
                                stroke-linecap="round"
                                stroke-width="2"
                            />
                        </g>
                    </svg>
                </td>
                <td class="w-1/6 txt-center">
                    <div class="roundCheckbox">
                        <input type="checkbox" class="h-5 w-5 "
                               :id="notification.data.id"
                               :checked="notification.read_at"
                               @click="setNotificationReadAt(notification)"
                        />
                        <label :for="notification.data.id"></label>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
        <!-- PAGINATION -->
        <div class="mt-5 pb-3">
            <div class="bg-grey-light rounded-xl shadow-md overflow-hidden px-4 py-2 text-left flex justify-end items-center space-x-4 sm:px-6 sm:rounded-md">
                <p  class="text-sm"> Page {{ currentPage }} sur {{ lastPage }}</p>
                <div class="text-left font-body text-deeppurple-dark space-x-3">
                    <button v-show="currentPage > 1" @click="paginationNotifications(1)" class="font-bold">
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 pt-1 fill-current text-deeppurple-dark" viewBox="0 0 24 24">
                            <path d="M17 17a1 1 0 01-.71-.29l-4-4a1 1 0 010-1.41l4-4a1 1 0 011.41 1.41L14.41 12l3.29 3.29A1 1 0 0117 17z"/>
                            <path d="M11 17a1 1 0 01-.71-.29l-4-4a1 1 0 010-1.41l4-4a1 1 0 011.41 1.41L8.41 12l3.29 3.29A1 1 0 0111 17z"/>
                        </svg>
                    </button>
                    <button v-show="currentPage > 1" @click="paginationNotifications(currentPage - 1)" class="font-bold">
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-7 h-7" viewBox="0 0 24 24">
                            <g fill="#490A7D">
                                <path fill="none" d="M0 0h24v24H0V0z"/>
                                <path d="M14.71 6.71c-.39-.39-1.02-.39-1.41 0L8.71 11.3c-.39.39-.39 1.02 0 1.41l4.59 4.59c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L10.83 12l3.88-3.88c.39-.39.38-1.03 0-1.41z"/>
                            </g>
                        </svg>
                    </button>
                    <button v-show="currentPage < lastPage" @click="paginationNotifications(currentPage + 1)" class="font-bold">
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-7 h-7" viewBox="0 0 24 24">
                            <g fill="#490A7D">
                                <path fill="none" d="M0 0h24v24H0V0z"/>
                                <path d="M9.29 6.71c-.39.39-.39 1.02 0 1.41L13.17 12l-3.88 3.88c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0l4.59-4.59c.39-.39.39-1.02 0-1.41L10.7 6.7c-.38-.38-1.02-.38-1.41.01z"/>
                            </g>
                        </svg>
                    </button>
                    <button v-show="currentPage < lastPage" @click="paginationNotifications(lastPage)" class="font-bold">
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 pt-1 fill-current text-deeppurple-dark" viewBox="0 0 24 24">
                            <path d="M7,17a1,1,0,0,1-.71-1.71L9.59,12,6.29,8.71A1,1,0,0,1,7.71,7.29l4,4a1,1,0,0,1,0,1.41l-4,4A1,1,0,0,1,7,17Z"/>
                            <path d="M13,17a1,1,0,0,1-.71-1.71L15.59,12,12.29,8.71a1,1,0,0,1,1.41-1.41l4,4a1,1,0,0,1,0,1.41l-4,4A1,1,0,0,1,13,17Z"/>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from '@/clients/api'
export default {
    name: 'NotificationsList',
    data () {
        return {
            notifications: [],
            currentPage: 1,
            lastPage: 1,
            dropdownOpen: false,
            notifications_read_at: [],
            loaded: false,
            filter_request: 'all'
        }
    },
    created () {
        this.paginationNotifications(1)
    },
    methods: {
        setNotificationReadAt (notification) {
            if (notification.read_at != null) {
                notification.read_at = null
                this.notifications_read_at.push(notification)
                this.sendNotificationToUpdate()
            } else {
                notification.read_at = Date.now()
                this.notifications_read_at.push(notification)
                this.sendNotificationToUpdate()
            }
        },
        deleteNotification (notification) {
            api.delete('/notifications/' + notification.data.id + '/delete')
                .then(() => {
                    this.paginationNotifications(this.currentPage)
                    this.$emit('changeNotificationNumber')
                })
                .catch(error => {
                    throw new Error(`API ${error}`)
                })
        },
        deleteAllNotifications () {
            api.delete('/notifications/delete')
                .then(() => {
                    this.paginationNotifications(this.currentPage)
                    this.$emit('changeNotificationNumber')
                })
                .catch(error => {
                    throw new Error(`API ${error}`)
                })
        },
        setFilterNotification () {
            if (this.filter_request === 'all') {
                this.filter_request = 'unread'
            } else {
                this.filter_request = 'all'
            }
            this.paginationNotifications(1)
        },
        setAllNotificationsReadAt () {
            for (let i = 0; this.notifications.data.length > i; i++) {
                if (this.notifications.data[i].read_at === null || this.notifications.data[i].read_at === '') {
                    this.notifications.data[i].read_at = Date.now()
                    this.notifications_read_at.push(this.notifications.data[i])
                }
            }
            this.sendNotificationToUpdate()
        },
        sendNotificationToUpdate () {
            if (this.notifications_read_at.length > 0) {
                this.notifications_to_send = JSON.stringify(this.notifications_read_at)
                const formData = new FormData()
                formData.append('notifications', this.notifications_to_send)
                api.post('/notifications/readat', formData)
                    .then(() => {
                        this.paginationNotifications(this.currentPage)
                        this.notification_read_at = []
                        this.$emit('changeNotificationNumber')
                    })
                    .catch(function (error) {
                        console.error(error.response)
                    })
            }
        },
        paginationNotifications (numPage) {
            if (numPage === 0) { numPage = 1 }
            const formData = new FormData()
            formData.append('filterRequest', this.filter_request)
            api.post('/notifications/all?&page=' + numPage, formData)
                .then(response => {
                    this.notifications = response.data
                    this.currentPage = response.data.meta.pagination.current_page
                    this.lastPage = response.data.meta.pagination.total_pages
                    this.loaded = true
                })
                .catch(error => {
                    throw new Error(`API ${error}`)
                })
        }
    }
}
</script>

<style scoped>
.notificationRead {
    opacity: 0.5;
}

.roundCheckbox {
    position: relative;
}

.roundCheckbox label {
    background-color: #fff;
    border: 1px solid #490A7D;
    border-radius: 50%;
    cursor: pointer;
    height: 1em;
    position: absolute;
    width: 1em;
}

.roundCheckbox label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 6px;
    left: 7px;
    opacity: 0;
    position: absolute;
    top: 8px;
    transform: rotate(-45deg);
    width: 12px;
}

.roundCheckbox input[type="checkbox"] {
    visibility: hidden;
}

.roundCheckbox input[type="checkbox"]:checked + label {
    background-color: #490A7D;
    border-color: #490A7D;
}

.roundCheckbox input[type="checkbox"]:checked + label:after {
    opacity: 0;
}
</style>
