<template>
    <div class="flex-1 relative pb-8 z-0 overflow-y-auto">
        <!-- Page header -->
        <div class="bg-white shadow mb-5">
            <div class="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
                <div class="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200">
                    <div class="flex-1 min-w-0">
                        <!-- Profile -->
                        <div class="flex items-center">
                            <img class="hidden h-16 w-16 rounded-full sm:block"
                                 src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.6&w=256&h=256&q=80"
                                 alt="">
                            <div>
                                <div class="flex items-center">
                                    <img class="h-16 w-16 rounded-full sm:hidden"
                                         src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.6&w=256&h=256&q=80"
                                         alt="">
                                    <h1 class="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate">
                                        Bonjour {{ user.first_name }},
                                    </h1>
                                </div>
                                <dl class="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                                    <dt class="sr-only">Status du compte</dt>
                                    <dd v-if="user.email_verified_at != null"
                                        class="mt-3 flex items-center text-sm text-gray-500 font-medium sm:mr-6 sm:mt-0 capitalize">
                                        <!-- Heroicon name: solid/check-circle -->
                                        <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                                             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                             aria-hidden="true">
                                            <path fill-rule="evenodd"
                                                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                                  clip-rule="evenodd"/>
                                        </svg>
                                        Compte vérifié
                                    </dd>
                                    <dd v-else
                                        class="mt-3 flex items-center text-sm text-gray-500 font-medium sm:mr-6 sm:mt-0 capitalize">
                                        <!-- Heroicon name: solid/x-circle -->
                                        <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-grey-400"
                                             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                             aria-hidden="true">
                                            <path fill-rule="evenodd"
                                                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                                  clip-rule="evenodd"/>
                                        </svg>
                                        Compte non vérifié
                                    </dd>
                                    <dt class="sr-only">Compte en ligne</dt>

                                    <dd v-if="user.is_available !== 0"
                                        class="mt-3 flex items-center text-sm text-gray-500 font-medium sm:mr-6 sm:mt-0 capitalize">
                                        <!-- Heroicon name: solid/check-circle -->
                                        <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                                             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                             aria-hidden="true">
                                            <path fill-rule="evenodd"
                                                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                                  clip-rule="evenodd"/>
                                        </svg>
                                        Compte en ligne
                                    </dd>
                                    <dd v-else
                                        class="mt-3 flex items-center text-sm text-gray-500 font-medium sm:mr-6 sm:mt-0 capitalize">
                                        <!-- Heroicon name: solid/x-circle -->
                                        <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-grey-400"
                                             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                             aria-hidden="true">
                                            <path fill-rule="evenodd"
                                                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                                  clip-rule="evenodd"/>
                                        </svg>
                                        Compte hors ligne
                                        <a href="#" @click="online">
                                            <span class="ml-1 text-xs hover:text-deeppurple-hover">
                                                (Mettre le compte en ligne)
                                            </span>
                                        </a>
                                    </dd>
                                </dl>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="py-16 xl:py-36 px-4 sm:px-6 lg:px-8 bg-white overflow-hidden">
            <div class="max-w-max lg:max-w-7xl mx-auto">
                <div class="relative z-10 mb-8 md:mb-2 md:px-6">
                    <div class="text-base max-w-prose lg:max-w-none">
                        <h2 class="leading-6 text-deeppurple-dark font-semibold tracking-wide uppercase">Transactions</h2>
                        <p class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                            Pourquoi payer des frais de mise en ligne ?
                        </p>
                    </div>
                </div>
                <div class="relative">
                    <svg class="hidden md:block absolute top-0 right-0 -mt-20 -mr-20" width="404" height="384" fill="none" viewBox="0 0 404 384" aria-hidden="true">
                        <defs>
                            <pattern id="95e8f2de-6d30-4b7e-8159-f791729db21b" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                                <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                            </pattern>
                        </defs>
                        <rect width="404" height="384" fill="url(#95e8f2de-6d30-4b7e-8159-f791729db21b)" />
                    </svg>
                    <svg class="hidden md:block absolute bottom-0 left-0 -mb-20 -ml-20" width="404" height="384" fill="none" viewBox="0 0 404 384" aria-hidden="true">
                        <defs>
                            <pattern id="7a00fe67-0343-4a3c-8e81-c145097a3ce0" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                                <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                            </pattern>
                        </defs>
                        <rect width="404" height="384" fill="url(#7a00fe67-0343-4a3c-8e81-c145097a3ce0)" />
                    </svg>
                    <div class="relative md:bg-white md:p-6">
                        <div class="lg:grid lg:grid-cols-2 lg:gap-6">
                            <div class="prose prose-indigo prose-lg text-gray-500 lg:max-w-none">
                                <p>L'accès à Keyros.io est gratuit. Néanmoins grâce à cette application, vous allez pouvoir percevoir
                                des revenus, notamment par le biais des accomptes de rendez-vous.</p>
                                <p>Ainsi, pour garantir une sécurité supplémentaire et dans le but de dissuader les escrocs, nous avons décidé
                                de mettre en place cette solution de frais de mise en ligne. Ces frais ne sont dûs qu'à la première
                                mise en ligne de votre compte.</p>
                            </div>
                            <div class="mt-6 prose prose-indigo prose-lg text-gray-500 lg:mt-0">
                                <p>Nous sommes conscients que cela peut engendrer une certaine déception de votre part, ainsi qu'une certaine
                                frustration, mais malgré tout nous vous demandons de vous plier à cette règle qui permet de garantir que la plateforme Keyros.io
                                ne sera pas envahie par un public indésirable pour vos clients.</p>
                            </div>
                        </div>
                        <div class="mt-5 lg:grid lg:grid-cols-1 lg:gap-6">
                            <div class="prose prose-indigo prose-lg text-purple-600 lg:max-w-none">
                                <p>Nous vous remercions de votre compréhension et nous vous souhaitons une bonne navigation sur Keyros.io .</p>
                                <p>L'équipe de Keyros.io</p>
                            </div>
                        </div>
                        <div class="mt-8 inline-flex rounded-md shadow">
                            <stripe-checkout
                                ref="checkoutRef"
                                mode="payment"
                                :pk="publishableKey"
                                :line-items="lineItems"
                                :success-url="successURL"
                                :cancel-url="cancelURL"
                                @loading="v => loading = v"
                            />
                            <button @click="submit" class="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-deeppurple-dark hover:bg-deeppurple-hover">
                                Payer les frais de mise en ligne
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { StripeCheckout } from '@vue-stripe/vue-stripe'

export default {
    components: {
        StripeCheckout,
    },
    data() {
        return  {
            user: {},
            publishableKey: '',
            loading: false,
            lineItems: [
                {
                    price: 'price_1JPwBNCDCEVyOcwvSjeUMcmq', // The id of the one-time price you created in your Stripe dashboard
                    quantity: 1,
                },
            ],
            successURL: 'http://localhost:8081/payment/success',
            cancelURL: 'http://localhost:8081/account/put-online',
        }
    },
    mounted () {
        this.$store.dispatch('user/getUserConnectedProfile')
        this.user = this.$store.state.user.user
        this.publishableKey = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY
    },
    methods: {
        online() {
            if (this.user.is_registration_bill_paid) {
                console.log("Let's go online buddy !")
            } else {
                this.$router.push({name: 'PutAccountOnline'})
            }
        },
        submit () {
            this.$refs.checkoutRef.redirectToCheckout();
        },
    }
}
</script>

