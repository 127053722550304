<template>
    <div class="container flex flex-col flex-1 lg:my-8 my-0 lg:flex-row lg:justify-items-start max-w-max lg:w-10/12">
        <breadcrumb :steps="steps"></breadcrumb>
        <div class="w-full flex-1 lg:w-10/12 bg-white rounded-r-none lg:rounded-r-xl shadow-lg overflow-hidden flex flex-col justify-between">
            <div class="mt-6 lg:mt-10 lg:mb-4 mb-0">
                <img class="h-10 mx-auto" src="/img/layout/logo.svg" alt="logo">
            </div>

            <div class="grid xl:grid-cols-4 xl:mx-0 mx-10">
                <div class="xl:col-start-2 xl:col-span-2 mb-8 w-full">
                    <div class="mt-4 mb-4 lg:mb-8">
                        <h1 class="text-deeppurple-dark text-center text-2xl font-bold mb-4">Informations générales</h1>
                        <p class="text-blacktext-dark text-sm font-light">
                            Actuellement, Projeo n'est disponible que pour les auto-entrepreneurs, indépendants,
                            micro-entrepreneurs, artistes, etc. Si vous n'avez ce status, patientez encore un peu !
                        </p>
                    </div>
                    <div class="mb-3 text-grey-dark text-sm">
                        <label for="name">
                            Nom de l'entreprise <span class="text-red-500 ml-1">*</span>
                        </label>
                        <input
                            type="text"
                            id="name"
                            :class="{ 'border border-red-500': $v.name.$error }"
                            class="w-full mt-2 mb-1 px-3 py-2 projeo-input"
                            placeholder="Ma petite entreprise"
                            required
                            v-model.trim="$v.name.$model"
                            @input="setName($event.target.value)"
                        >
                        <span class="text-red-500 text-xs" v-if="$v.name.$error">
                            Le nom de l'organisation est obligatoire
                        </span>
                    </div>
                    <div class="mb-3 text-grey-dark text-sm">
                        <label for="email">
                            Adresse courriel
                            <span class="text-red-500 ml-1">*</span>
                        </label>
                        <input
                            type="email"
                            id="email"
                            :class="{ 'border border-red-500': $v.email.$error }"
                            class="w-full mt-2 mb-1 px-3 py-2 projeo-input"
                            placeholder="john@doe.com"
                            required
                            v-model.trim="$v.email.$model"
                            @input="setEmail($event.target.value)"
                        >
                        <span class="text-red-500 text-xs" v-if="$v.email.$error">
                            L'adresse courriel est obligatoire
                        </span><br>
                        <span class="text-red-500 text-xs" v-if="!$v.email.email">
                            L'adresse courriel n'a pas le bon format
                        </span>
                    </div>
                    <div class="mb-3 text-grey-dark text-sm">
                        <label>
                            Mot de passe
                            <span class="text-red-500 ml-1">*</span>
                        </label>
                        <input
                            type="password"
                            id="password"
                            v-model.trim="$v.password.$model"
                            @input="setPassword($event.target.value)"
                            :class="{ 'border border-red-500': $v.password.$error }"
                            class="w-full mt-2 mb-1 px-3 py-2 projeo-input"
                            placeholder="***********"
                            required
                        >
                        <span class="text-red-500 text-xs" v-if="$v.password.$error">
                            Le mot de passe est obligatoire
                        </span><br>
                        <span class="text-red-500 text-xs" v-if="!$v.password.minLength">
                            Votre mot de passe est trop court ({{$v.password.$params.minLength.min}} minimum)
                        </span>
                    </div>
                    <div class="mb-3 text-grey-dark text-sm">
                        <label>
                            Confirmer le mot de passe
                            <span class="text-red-500 ml-1">*</span>
                        </label>
                        <input
                            type="password"
                            id="confirm_password"
                            v-model.trim="$v.confirm_password.$model"
                            @input="setConfirmPassword($event.target.value)"
                            :class="{ 'border border-red-500': $v.confirm_password.$error }"
                            class="w-full mt-2 mb-1 px-3 py-2 projeo-input"
                            placeholder="***********"
                            required
                        >
                        <span class="text-red-500 text-xs" v-if="$v.confirm_password.$error">
                            Veuillez confirmer votre mot de passe
                        </span><br>
                        <span class="text-red-500 text-xs" v-if="!$v.confirm_password.sameAs">
                            La confirmation du mot de passe ne correspond pas
                        </span>
                    </div>
                    <div class="flex space-x-5 text-grey-dark text-sm">
                        <div class="w-1/2">
                            <label>
                                Pays
                                <span class="text-red-500 ml-1">*</span>
                            </label>
                            <v-select
                                label="name"
                                class="w-full mt-2 mb-1 p-1 projeo-input"
                                :options="countries"
                                v-model.trim="$v.country_id.$model"
                                :reduce="name => `${name.id}`"
                                @input="setCountrySelected"
                            />
                            <span class="text-red-500 text-xs" v-if="$v.country_id.$error">
                                Veuillez choisir un pays
                            </span>
                        </div>
                        <div class="w-1/2">
                            <label>
                                Devise
                                <span class="text-red-500 ml-1">*</span>
                            </label>
                            <v-select
                                label="name"
                                class="w-full mt-2 mb-1 p-1 projeo-input"
                                :options="currencies"
                                v-model.trim="$v.currency_id.$model"
                                :reduce="name => `${name.id}`"
                                @input="setCurrencySelected"
                            />
                            <span class="text-red-500 text-xs" v-if="$v.currency_id.$error">
                                Veuillez choisir une devise
                            </span>
                        </div>
                    </div>
                    <div v-if="errors.length > 0" class="rounded-md bg-red-50 border border-red-300 p-4 mt-3">
                        <div class="flex">
                            <!-- Heroicon name: x-circle -->
                            <svg class="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
                            </svg>
                            <div class="ml-3">
                                <h3 class="text-sm font-medium text-red-800">
                                    Le formulaire comporte des erreurs
                                </h3>
                                <div class="mt-2 text-sm text-red-700">
                                    <ul v-for="error in errors" :key="error" class="list-disc pl-5 space-y-1">
                                        <li>{{ error }}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex justify-end mx-10">
                <button
                    class="text-base px-6 py-2 projeo-btn-deeppurple"
                    type="button"
                    id="btn-next-step"
                    @click="validateStep"
                >
                    <span class="hidden lg:block mr-2">Étape suivante</span>
                    <font-awesome-icon :icon="['fas', 'arrow-right']" />
                </button>
            </div>
            <div class="lg:my-4 my-2 w-full">
                <p class="text-copyright flex items-center justify-center text-deeppurple-dark text-xs">2021 - Tous droits réservés</p>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import Breadcrumb from './breadcrumb'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import { validationMixin } from 'vuelidate'
import { required, minLength, sameAs, email } from 'vuelidate/lib/validators'

export default {
    name: 'stepOne',
    components: { Breadcrumb, vSelect },
    mixins: [validationMixin],
    data () {
        return {
            currencies: [],
            countries: [],
            name: null,
            email: null,
            password: null,
            confirm_password: null,
            country_id: null,
            currency_id: null,
            errors: [],
            steps: 1
        }
    },
    validations: {
        name: {
            required
        },
        email: {
            required,
            email
        },
        password: {
            required,
            minLength: minLength(8)
        },
        confirm_password: {
            required,
            minLength: minLength(8),
            sameAs: sameAs('password')
        },
        country_id: {
            required
        },
        currency_id: {
            required
        }
    },
    mounted () {
        axios.get(`${process.env.VUE_APP_URL_API}/currencies`).then(response => {
            this.currencies = response.data.data
        })

        axios.get(`${process.env.VUE_APP_URL_API}/countries`).then(response => {
            this.countries = response.data.data
        })

        if (localStorage.getItem('tenant')) {
            this.name = JSON.parse(localStorage.getItem('tenant')).name
            this.country_id = JSON.parse(localStorage.getItem('tenant')).country_id
            this.currency_id = JSON.parse(localStorage.getItem('tenant')).currency_id
        }

        if (localStorage.getItem('user')) {
            this.email = JSON.parse(localStorage.getItem('user')).email
        }
    },
    methods: {
        setName (value) {
            this.name = value
            this.$v.name.$touch()
        },
        setEmail (value) {
            this.email = value
            this.$v.email.$touch()
        },
        setPassword (value) {
            this.password = value
            this.$v.password.$touch()
        },
        setConfirmPassword (value) {
            this.confirm_password = value
            this.$v.confirm_password.$touch()
        },
        setCurrencySelected (value) {
            this.currency_id = value
            this.$v.currency_id.$touch()
        },
        setCountrySelected (value) {
            this.country_id = value
            this.$v.country_id.$touch()
        },
        validateStep () {
            this.errors = []
            this.$v.$touch()

            if (this.$v.$invalid) {
                this.errors.push('Le formulaire est invalide.')
            } else {
                const config = {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('jwt.token')
                    }
                }

                const formData = {
                    name: this.name,
                    email: this.email,
                    password: this.password,
                    password_confirmation: this.confirm_password,
                    country_id: this.country_id,
                    currency_id: this.currency_id
                }

                const that = this

                axios.post(process.env.VUE_APP_URL_API + '/register/step-one', formData, config)
                    .then(response => {
                        this.$toasted.success('Première étape validée', {
                            position: 'bottom-right',
                            duration: 2000
                        })

                        localStorage.setItem('tenant', JSON.stringify(response.data.tenant))
                        localStorage.setItem('user', JSON.stringify(response.data.user))
                        this.$router.push({ name: 'StepTwo' })
                    }, function (error) {
                        that.errors.push(error.response.data)
                        console.log(error.response.data.errors)
                    })
            }
        }
    }
}
</script>
