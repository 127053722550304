<template>
    <div class="flex text-left items-end">
        <!-- close the options menu when clicking outside of the component -->
        <div v-if="IsShowOptions" class="w-full h-full fixed top-0 left-0" v-on:click="away"></div>
        <div>
            <button @click.prevent="showOption"
                    class="rounded-full flex items-center text-grey-dark focus:outline-none focus:bg-deeppurple-light focus:bg-opacity-40"
                    id="options-menu" aria-haspopup="true" aria-expanded="true">
                <font-awesome-icon :icon="['fas', 'ellipsis-v']"/>
            </button>
        </div>
        <div v-show="IsShowOptions"
             class="origin-top-right absolute right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
            <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                <button
                    @click="askEdit"
                    type="button"
                    class="flex w-full text-left space-x-3 px-4 py-2 text-sm text-gray-700 hover:bg-gray-100
                    hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-blacktext-regular"
                    role="menuitem"
                >
                    <font-awesome-icon :icon="['fas', 'edit']"/>
                    Editer
                </button>
                <button
                    v-if="isDeleted"
                    @click="askConfirm"
                    type="button"
                    class="flex w-full text-left space-x-3 px-4 py-2 text-sm text-gray-700 hover:bg-gray-100
                    hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-blacktext-regular"
                    role="menuitem"
                >
                    <font-awesome-icon :icon="['fas', 'trash-restore']"/>
                    Restaurer
                </button>
                <button
                    v-else-if="!isDeleted"
                    @click="askConfirm"
                    type="button"
                    class="flex w-full text-left space-x-3 px-4 py-2 text-sm text-gray-700 hover:bg-gray-100
                    hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-blacktext-regular"
                    role="menuitem"
                >
                    <font-awesome-icon :icon="['fas', 'trash']"/>
                    Supprimer
                </button>
                <button
                    v-if="isDownload"
                    @click="askDownload"
                    type="button"
                    class="flex w-full text-left space-x-3 px-4 py-2 text-sm text-gray-700 hover:bg-gray-100
                    hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-blacktext-regular"
                    role="menuitem"
                >
                    <font-awesome-icon :icon="['fas', 'download']"/>
                    Télécharger
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['isDownload', 'isDeleted'],
    data () {
        return {
            IsShowOptions: false
        }
    },
    methods: {
        showOption () {
            this.IsShowOptions = !this.IsShowOptions
        },
        away () {
            this.IsShowOptions = false
        },
        askEdit () {
            this.$emit('edit')
        },
        askConfirm () {
            this.$emit('confirm')
        },
        askDelete () {
            this.$emit('delete')
        },
        askDownload () {
            this.$emit('download')
        }
    }
}
</script>
