<template>
    <div class="inline-block mr-4">
        <div class="w-44 h-44 max-w-xs flex flex-col relative justify-center items-center overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out">
            <!-- menu button -->
            <div class="absolute right-0 top-1">
                <menu-button :isDownload="true" :isDeleted="isDeleted" @edit="askEdit" @confirm="askConfirm" @download="askDownload" />
            </div>
            <!-- pdf img -->
            <div class="my-4">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" class="w-16 h-16">
                    <path fill="#eff2f3" d="M58.2 3.2v49H5.8V13.8L19.6 0h35.5c1.7 0 3.1 1.5 3.1 3.2z"/>
                    <path fill="#dadede" d="M16.7 13.8H5.9L19.6 0l-.1 10.8c.1 1.7-1.2 3-2.8 3z"/>
                    <path fill="#f2786b" d="M37.1 28c-2.9-2.8-5-6-5-6 .8-1.2 2.7-8.1-.2-10.1-2.9-2-4.4 1.7-4.4 1.7-1.3 4.6 1.8 8.8 1.8 8.8l-3.5 7.7c-.4 0-11.5 4.3-7.7 9.6 3.9 5.3 9.3-7.5 9.3-7.5 2.1-.7 8.5-1.6 8.5-1.6 2.5 3.4 5.5 4.5 5.5 4.5 4.6 1.2 5.1-2.6 5.1-2.6.1-5-9.4-4.5-9.4-4.5zM20 37.9c-.1 0-.1-.1-.1-.1-.6-1.4 4-4.1 4-4.1s-2.5 4.8-3.9 4.2zm10.4-24.2c1.3 1.2.2 5.3.2 5.3s-1.5-4.1-.2-5.3zm-1.2 15.5l1.8-4.4 2.8 3.4-4.6 1zM44 32.4c-.8 1.3-4.1-1.5-4.4-1.8.5 0 4.8.3 4.4 1.8zM58.2 49v11.8c0 1.7-1.4 3.2-3.2 3.2H8.9c-1.7 0-3.2-1.4-3.2-3.2V49h52.5z"/>
                    <path fill="#eff2f3" d="M27.9 54.2c0 .8-.3 1.4-.8 1.9-.5.4-1.3.6-2.3.6H24v2.9h-1.3v-7.7H25c1 0 1.7.2 2.2.6.5.4.7.9.7 1.7zm-3.8 1.5h.7c.6 0 1.1-.1 1.4-.3.3-.2.5-.6.5-1.1 0-.4-.1-.8-.4-1-.3-.2-.7-.3-1.3-.3h-.9v2.7zm11.7 0c0 1.3-.4 2.3-1.1 2.9-.7.7-1.7 1-3.1 1h-2.2v-7.7h2.4c1.2 0 2.2.3 2.9 1 .7.6 1.1 1.6 1.1 2.8zm-1.4 0c0-1.9-.9-2.8-2.6-2.8h-1.1v5.6h.9c1.9.1 2.8-.9 2.8-2.8zm4.3 3.9h-1.3v-7.7h4.4V53h-3.1v2.4h2.9v1.1h-2.9v3.1z"/>
                </svg>
            </div>
            <!-- file name -->
            <div class="font-sans text-base text-deeppurple-dark">{{ fileType.name }} n°{{ file.number }}</div>
            <div class="font-sans text-sm italic text-blacktext-dark">filename.pdf</div>
        </div>
    </div>
</template>

<script>
import MenuButton from '@/components/Customers/MenuButton'

export default {
    name: 'Card',
    props: ['fileType', 'file', 'isDeleted'],
    components: {
        MenuButton
    },
    methods: {
        askEdit () {
            console.log('edit in card')
        },
        askDelete () {
            console.log('delete in card')
        },
        askConfirm () {
            this.$emit('askDelete', this.file.id)
        },
        askDownload () {
            console.log('download in card')
        }
    }
}
</script>
