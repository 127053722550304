/**
 * The token repository.
 * @returns {Object} The public object.
 */
export default function () {
    /** @type {string} */
    const name = 'jwt.token'

    /**
   * Store the token.
   * @param {string} value - The value of the token.
   * @returns {void}
   */
    function store (value) {
        localStorage.setItem(name, value)
    }

    /**
   * Read the token.
   * @returns {string} The value of the token.
   */
    function read () {
        return localStorage.getItem(name)
    }

    /**
   * Destroy the token.
   * @return {void}
   */
    function destroy () {
        localStorage.removeItem(name)
    }

    return {
        read,
        store,
        destroy
    }
}
