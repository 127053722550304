/**
 * The availabilities repository.
 * @author Christophe Lablancherie <christophelablancherie@gmail.com>
 * @param {Object} client - The injected client.
 * @returns {Object} The public object.
 */
export default function (client) {
    return {
        /**
         * Get all availabilities.
         * @returns {Promise} Promise object represents the HTTP request.
         */
        get () {
            return client.get('/availabilities').then(
                response => response.data
            )
        },

        /**
         * Store an availability.
         *
         * @param data
         *
         * @returns {Promise}
         */
        store (data) {
            return client.post('/availabilities', data).then(response => {
                return response.data
            })
        },

        /**
         * Update an availability.
         * @returns {Promise} Promise object represents the HTTP request.
         */
        update (data) {
            return client.put('/availabilities/' + data.id, data).then(
                response => response.data
            )
        },

        /**
         * delete an availability.
         * @returns {Promise} Promise object represents the HTTP request.
         */
        delete(id) {
            return client.delete('/availabilities/' + id).then(
                response => {
                    return response.data
                }
            )
        }
    }
}
