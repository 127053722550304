<template>
    <transition name="fade">
        <div class="fixed z-40 inset-0 overflow-y-auto">
            <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0" >
                <!-- Background overlay, show/hide based on modal state. -->
                <div class="fixed inset-0 transition-opacity" aria-hidden="true" >
                    <div class="absolute inset-0 bg-gray-600 opacity-75" @click.self="closeModal"></div>
                </div>

                <!-- This element is to trick the browser into centering the modal contents. -->
                <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                <!-- Modal -->
                <div class="inline-block align-bottom bg-white rounded-lg px-10 pt-6 pb-8 text-left overflow-hidden transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                    <div class="h-full">
                        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="920.304" height="515.087" viewBox="0 0 920.304 515.087" class="h-52 w-full center pb-5">
                            <path data-name="Path 1" d="M193.679 513.126h-140.6s-2.85-41.8 14.012-42.275 14.962 18.525 36.1-7.6 46.787-24.7 50.112-9.262-6.412 27.787 11.4 23.987 43.463 6.175 28.976 35.15z" fill="#e6e6e6"/>
                            <path data-name="Path 2" d="M121.006 513.136l-.475-.02c1.107-27.52 6.87-45.2 11.511-55.19 5.038-10.843 9.893-15.233 9.942-15.276l.316.355c-.048.043-4.846 4.389-9.844 15.16-4.615 9.944-10.347 27.554-11.45 54.97z" fill="#fff"/>
                            <path data-name="Path 3" d="M171.892 513.192l-.456-.133a73.682 73.682 0 0118.551-30.863l.319.352a73.183 73.183 0 00-18.414 30.644z" fill="#fff"/>
                            <path data-name="Path 4" d="M75.225 513.159l-.471-.065a51.732 51.732 0 00-4.653-30.238 41.633 41.633 0 00-7.34-10.716l.329-.343a42.155 42.155 0 017.441 10.848 52.206 52.206 0 014.694 30.514z" fill="#fff"/>
                            <path data-name="Path 5" d="M212.76 513.76H37.915l-.04-.591c-.1-1.473-2.331-36.228 8.93-48.63a12.334 12.334 0 019.013-4.324c7.34-.21 11.953 2.54 15.674 4.747 7.013 4.16 11.645 6.909 28.49-13.91 18.247-22.553 37.936-27.406 49.254-24.708 7.313 1.744 12.353 6.67 13.828 13.52 1.4 6.485.952 12.578.594 17.475-.383 5.239-.685 9.376 1.467 11.252 1.8 1.565 5.381 1.707 11.281.448 12-2.56 28.748-.37 37.153 10.49 4.522 5.844 8.085 16.464-.623 33.88zm-173.652-1.27h172.865c6.489-13.165 6.692-24.287.581-32.182-7.711-9.963-23.888-12.585-35.883-10.026-6.411 1.368-10.23 1.142-12.381-.732-2.631-2.292-2.308-6.71-1.9-12.3.352-4.815.789-10.808-.569-17.115-1.368-6.351-6.063-10.926-12.881-12.551-10.957-2.614-30.1 2.177-47.971 24.27-17.534 21.672-22.817 18.54-30.126 14.2-3.767-2.234-8.043-4.767-14.99-4.57a11.109 11.109 0 00-8.108 3.909c-10.118 11.146-8.884 42.398-8.636 47.097z" fill="#2f2e41"/>
                            <path data-name="Path 8" d="M784.06 514.467H611.844l-.033-.965-8.223-235.18h188.727zm-170.284-2h168.352l8.117-232.145H605.658z" fill="#3f3d56"/>
                            <g data-name="Group 1" fill="#3f3d56">
                                <path data-name="Rectangle 17" d="M639.826 321.897h13.099v162.097h-13.099z"/>
                                <path data-name="Rectangle 18" d="M691.402 321.897h13.099v162.097h-13.099z"/>
                                <path data-name="Rectangle 19" d="M742.978 321.897h13.099v162.097h-13.099z"/>
                            </g>
                            <path d="M901.75 347.382l-.846-.534L686.99 211.665l18.555-29.362.846.534L920.304 318.02zm-212-136.34l211.376 133.58 16.418-25.98-211.376-133.58z" fill="#3f3d56"/>
                            <path data-name="Path 10" d="M850.102 200.77a38.459 38.459 0 00-58.62 38.07l10.2 6.446a30.344 30.344 0 1128.98 18.32l10.2 6.447a38.459 38.459 0 009.249-69.283z" fill="#3f3d56"/>
                            <path data-name="Rectangle 21" fill="#3f3d56" d="M0 513.087h909v2H0z"/>
                            <g data-name="Group 6">
                                <path data-name="Path 111" d="M397.037 499.44h-14.564l-6.932-56.174h21.5z" fill="#feb8b8"/>
                                <path data-name="Path 112" d="M354.386 512.962h45.771v-17.684h-28.332a17.439 17.439 0 00-17.439 17.439z" fill="#2f2e41"/>
                                <path data-name="Path 113" d="M391.918 412.395l10.046 10.545 45.452-33.727-14.826-15.563z" fill="#feb8b8"/>
                                <path data-name="Path 114" d="M412.313 427.77l-19.549-20.512-12.803 12.202 31.582 33.139.177-.17a17.441 17.441 0 00.593-24.658z" fill="#2f2e41"/>
                                <path data-name="Path 115" d="M290.548 258.497a11.946 11.946 0 015.715-17.4L353.442 95.37l22.288 13.345-63.518 139.8a12.01 12.01 0 01-21.664 9.982z" fill="#feb8b8"/>
                                <path data-name="Path 116" d="M507.58 268.942a11.945 11.945 0 01-10.727-14.85l-84.354-131.87 23.891-10.2 75.836 133.524a12.01 12.01 0 01-4.646 23.4z" fill="#feb8b8"/>
                                <path data-name="Path 117" d="M354.005 243.905l14.931 221.913 35.682-3.148 7.346-163.722 19.94 70.314 43.028 3.148-17.031-139z" fill="#2f2e41"/>
                                <path data-name="Path 118" d="M438.201 358.768l-6.3 10.495-44.073 30.434 31.484 16.792s60.869-33.583 55.622-44.078z" fill="#2f2e41"/>
                                <path d="M322.759 154.12l12.421-35a62.494 62.494 0 0132.332-35.667 89.427 89.427 0 0152.484-2.873l4.52 1.122a87.364 87.364 0 0133.128 16c7.654 6.034 14.54 13.674 15.153 21.892a.244.244 0 00.015.05c2.12 9.293 3.169 57.568 3.169 57.568h-18.7l2.958 65.067-.239-.471s-107.856 20.41-107.856 9.916v-67.168l-2.211-24.32z" fill="#ccc"/>
                                <circle cx="423.432" cy="41.593" r="29.889" fill="#feb8b8"/>
                                <path d="M427.91 28.189l23.207.93c2.92-.01 6.108-.112 8.332-2 3.35-2.85 2.79-8.225.995-12.241-5-11.182-16.153-15.188-28.4-14.86s-25.08 4.48-31.675 14.8-8.377 23.353-5.893 35.345a38.534 38.534 0 0131.508-21.97z" fill="#2f2e41"/>
                            </g>
                            <path d="M565.723 320.545l-84.001-58.873a3.607 3.607 0 01-.882-5.015l65.931-94.073a3.607 3.607 0 015.015-.882l84.001 58.873a3.607 3.607 0 01.882 5.015l-65.93 94.07a3.607 3.607 0 01-5.016.885z" fill="#490A7D"/>
                            <path d="M584.614 256.664l-49.29-34.546a5.3 5.3 0 116.084-8.681l49.29 34.545a5.3 5.3 0 01-6.084 8.682zM573.302 272.804l-49.29-34.545a5.3 5.3 0 116.084-8.682l49.29 34.546a5.3 5.3 0 01-6.084 8.681zM561.868 289.119l-49.29-34.546a5.3 5.3 0 116.083-8.681l49.291 34.545a5.3 5.3 0 01-6.084 8.682zM584.476 224.734l-19.092-13.381a5.3 5.3 0 116.084-8.681l19.092 13.38a5.3 5.3 0 01-6.084 8.682z" fill="#fff"/>
                        </svg>
                        <div v-if="estimateId">
                            <h4 class="text-lg text-center text-deeppurple-dark font-sans font-semibold mb-8 my-3">Êtes-vous sûr de vouloir supprimer ce devis ?</h4>
                            <div class="flex justify-end">
                                <button @click="closeModal" type="button" class="inline-flex justify-center items-center px-5 py-1">
                                    Annuler
                                </button>
                                <button @click="deleteEstimate" type="button" class="inline-flex justify-center items-center px-5 py-1 rounded-2xl bg-deeppurple-dark text-white hover:bg-deeppurple-hover">
                                    Supprimer
                                </button>
                            </div>
                        </div>
                        <div v-else-if="invoiceId">
                            <h4 class="text-lg text-center text-deeppurple-dark font-sans font-semibold mb-8 my-3">Êtes-vous sûr de vouloir supprimer cette facture ?</h4>
                            <div class="flex justify-end">
                                <button @click="closeModal" type="button" class="inline-flex justify-center items-center px-5 py-1">
                                    Annuler
                                </button>
                                <button @click="deleteInvoice" type="button" class="inline-flex justify-center items-center px-5 py-1 rounded-2xl bg-deeppurple-dark text-white hover:bg-deeppurple-hover">
                                    Supprimer
                                </button>
                            </div>
                        </div>
                         <div v-else-if="customer">
                            <h4 v-if="customer.deleted_at" class="text-lg text-center text-deeppurple-dark font-sans font-semibold mb-8 my-3">Êtes-vous sûr de vouloir restaurer ce client ?</h4>
                            <h4 v-else class="text-lg text-center text-deeppurple-dark font-sans font-semibold mb-8 my-3">Êtes-vous sûr de vouloir supprimer ce client ?</h4>
                            <div class="flex justify-end">
                                <button @click="closeModal" type="button" class="inline-flex justify-center items-center px-5 py-1">
                                    Annuler
                                </button>
                                <button v-if="customer.deleted_at" @click="restoreCustomer" type="button" class="inline-flex justify-center items-center px-5 py-1 rounded-2xl bg-deeppurple-dark text-white hover:bg-deeppurple-hover">
                                    Restaurer
                                </button>
                                <button v-else @click="deleteCustomer" type="button" class="inline-flex justify-center items-center px-5 py-1 rounded-2xl bg-deeppurple-dark text-white hover:bg-deeppurple-hover">
                                    Supprimer
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>

export default {
    props: ['customer', 'estimateId', 'invoiceId'],
    methods: {
        closeModal () {
            this.$emit('closeModal')
        },
        deleteCustomer () {
            this.$emit('deleteCustomer', this.customer.id)
            this.$emit('closeModal')
        },
        restoreCustomer () {
            this.$emit('restore', this.customer.id)
            this.$emit('closeModal')
        },
        deleteEstimate () {
            this.$emit('deleteEstimate', this.estimateId)
            this.$emit('closeModal')
        },
        deleteInvoice () {
            this.$emit('deleteInvoice', this.invoiceId)
            this.$emit('closeModal')
        }
    }
}
</script>
