/**
 * The user repository.
 * @author Christophe Lablancherie <clablancherie@projeo.io>
 * @param {Object} client - The injected client.
 * @returns {Object} The public object.
 */
export default function (client) {
    return {
        /**
         * Get User Details.
         * @returns {Promise} Promise object represents the HTTP request.
         */
        getUserProfile () {
            return client.get('/me').then(
                response => response.data
            )
        }
    }
}
