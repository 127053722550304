<template>
  <div id="app">
    <component :is="layout">
      <router-view/>
    </component>
  </div>
</template>

<script>
const defaultLayout = 'auth'

export default {
    computed: {
        layout () {
            return (this.$route.meta.layout || defaultLayout) + '-layout'
        }
    }
}
</script>
