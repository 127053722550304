import {benefitRepository} from '@/container'

export default {
    namespaced: true,
    state: {
        benefits: []
    },
    getters: {},
    actions: {
        getBenefits ({ commit }) {
            benefitRepository().get().then(response => {
                commit('SET_BENEFITS', response)
            })
        },
        create ({ commit }, {vm, formData}) {
            benefitRepository().store(formData).then(response => {
                commit('ADD_BENEFIT', response.benefit)
                vm.$toasted.success(response.message, {
                    position: 'top-center',
                    duration: 2000
                })
            }).catch(error => {
                throw new Error(`API ${error}`)
            })
        },
        update ({ commit }, {vm, data}) {
            benefitRepository().update(data).then(response => {
                commit('UPDATE_BENEFIT', response.benefit, data.id)
                vm.$toasted.success(response.message, {
                    position: 'top-center',
                    duration: 2000
                })
            }).catch(error => {
                throw new Error(`API ${error}`)
            })
        },
        duplicate ({ commit }, {vm, data}) {
            benefitRepository().duplicate(data).then(response => {
                commit('ADD_BENEFIT', response.benefit, data.id)
                vm.$toasted.success(response.message, {
                    position: 'top-center',
                    duration: 2000
                })
            }).catch(error => {
                throw new Error(`API ${error}`)
            })
        },
        delete ({ commit }, {vm, data}) {
            benefitRepository().delete(data.id).then(response => {
                commit('DELETE_BENEFIT', data.id)
                vm.$toasted.success(response.message, {
                    position: 'top-center',
                    duration: 2000
                })
            }).catch(error => {
                throw new Error(`API ${error}`)
            })
        },
    },
    mutations: {
        ADD_BENEFIT (state, benefit) {
            state.benefits.push(benefit)
        },
        UPDATE_BENEFIT (state, benefit, id) {
            const index = state.benefits.findIndex(benefit => benefit.id === id)
            state.benefits[index] = benefit
        },
        DELETE_BENEFIT (state, id) {
            const index = state.benefits.findIndex(benefit => benefit.id === id)
            state.benefits.splice(index, 1)
        },
        SET_BENEFITS (state, data) {
            state.benefits = data.data
        }
    }
}
